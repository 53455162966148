<mat-form-field
  appearance="fill"
  class="drop-down-input"
  [class.drop-down-input-active]="showPanel$ | async"
  cdkOverlayOrigin
  #originOverlay="cdkOverlayOrigin"
  floatLabel="never"
>
  <mat-label>{{ placeholderName }}</mat-label>
  <input
    [type]="functionality === 1 ? 'number' : 'text'"
    matInput
    class="no-spin"
    [formControl]="searchCtrl"
    id="searchbox"
  />
</mat-form-field>
<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  [cdkConnectedOverlayOrigin]="originOverlay"
  [cdkConnectedOverlayOpen]="(showPanel$ | async) || false"
  [cdkConnectedOverlayPositions]="positions"
>
  <section class="drop-down-serch-panel mat-elevation-z1">
    <div class="drop-down-serch-panel_controls"></div>
    <div class="panel-result-section">
      <div
        class="result-item"
        *ngFor="let item of filteredItems$ | async; let i = index"
      >
        <div class="filter-dropdown-item">
          <div class="filter-item-part1">
            <span *ngIf="functionality === 0">
              {{ item?.name }} // {{ item.subtrade_name }}
            </span>
            <div class="font-user">
              <span
                *ngIf="functionality === 0 && resultType === 'DRY_CLEANING'"
              >
                Per KG. / {{ item?.standard_price | currency : "INR" }}
              </span>
              <span *ngIf="functionality === 0 && resultType === 'IRONING'">
                Per Cloth / {{ item?.premium_price | currency : "INR" }}
              </span>
              <span *ngIf="functionality === 1">{{ item?.mobile }} /</span>
              <span *ngIf="functionality === 1"
                >{{ item.name ? item.name : "User" }}
              </span>
            </div>
            <span *ngIf="functionality === 2"
              >{{ item?.name }} // {{ item.email }} //
              {{ item.booking_code }} //</span
            >
          </div>
          <span
            *ngIf="functionality === 0"
            class="filter-item-part2"
            (click)="onItemClick(item, checkIfSelectedItemPresent(item))"
            [ngClass]="{ selected: checkIfSelectedItemPresent(item) }"
            >{{ !checkIfSelectedItemPresent(item) ? "Add" : "Added" }}</span
          >
          <span
            *ngIf="functionality === 1"
            class="filter-item-part2"
            (click)="onItemClick(item, checkIfSelectedItemPresent(item))"
            [ngClass]="{ selected: checkIfSelectedItemPresent(item) }"
            >{{ "view" }}</span
          >
          <span
            *ngIf="functionality === 2"
            class="filter-item-part2"
            (click)="onItemClick(item, checkIfSelectedItemPresent(item))"
            [ngClass]="{ selected: checkIfSelectedItemPresent(item) }"
            >{{ "view" }}</span
          >
        </div>
      </div>
      <div class="result-item" *ngIf="!(filteredItems$ | async)?.length">
        <div class="filter-dropdown-item">
          <span *ngIf="functionality === 0">No Record Found</span>
          <span
            class="new-user"
            *ngIf="functionality === 1 && itemList.length === 0"
            (click)="handleRouteUser('NEW')"
            >New User
          </span>
        </div>
        <span
          *ngIf="functionality === 1"
          class="filter-item-part2"
          style="color: #0f5132"
          >{{ searchCtrl.value }}
        </span>
      </div>
    </div>
  </section>
</ng-template>
