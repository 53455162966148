<div class="filter-dropdown">
    <div class="filter-btn" (click)="toggleDropdown($event)">
      <img [src]="calendarIcon" alt="calendar">
      {{ activeFilter }}
      <img [src]="dropdownIcon" alt="dropdown">
    </div>
  
    <div *ngIf="isOpen">
      <div class="date-type-section">
        <div class="section-title">Apply On</div>
        <div class="radio-group">
          <label class="radio-label">
            <input
              type="radio"
              name="dateType"
              value="Pickup Date"
              [(ngModel)]="selectedDateType"
              (change)="onDateTypeChange()"
            />
            Pickup Date
          </label>
          <label class="radio-label">
            <input
              type="radio"
              name="dateType"
              value="Delivery Date"
              [(ngModel)]="selectedDateType"
              (change)="onDateTypeChange()"
            />
            Delivery Date
          </label>
        </div>
      </div>
  
      <div class="date-options">
        <div
          *ngFor="let option of dateOptions"
          class="date-option"
          [class.active]="activeFilter === option"
          (click)="selectDateOption(option)"
        >
          {{ option }}
        </div>
      </div>
  
      <div class="custom-date-inputs" *ngIf="activeFilter === 'Custom' && selectedDateType">
        <input
          type="date"
          [max]="dateRange.to"
          [(ngModel)]="dateRange.from"
          (change)="handleCustomDateChange()"
        />
        <input
          type="date"
          [min]="dateRange.from"
          [(ngModel)]="dateRange.to"
          (change)="handleCustomDateChange()"
        />
      </div>
    </div>
  </div>