<!-- Add Runner Modal -->
<div *ngIf="functionality === 1" class="modal-overlay">
  <div class="add-runner">
    <div class="header">
      <span>ADD RUNNER</span>
      <button class="close-btn" (click)="closeModal()">✕</button>
    </div>

    <!-- Custom Notification -->
    <div *ngIf="showCustomNotification" class="custom-notification">
      <span>{{ customNotificationMessage }}</span>
      <span class="close-notification" (click)="showCustomNotification = false">×</span>
    </div>

    <div class="form-container">
      <!-- Name Field -->
      <div class="form-flex">
        <div class="form-item">
          <label class="field-label">Name<span class="text-danger">*</span></label>
          <input
            [(ngModel)]="name"
            placeholder="Enter runner name"
            class="input-field"
          />
        </div>
      </div>

      <!-- Phone Number Field -->
      <div class="form-flex">
        <div class="form-item">
          <label class="field-label">Phone No<span class="text-danger">*</span></label>
          <input
            type="number"
            class="input-field no-spinner"
            [(ngModel)]="mobile"
            name="mobile"
            #mobileInput="ngModel"
            (keyup)="handleChange($event, 'mobile')"
            (blur)="checkExistingRunner(mobile)"
            required
            pattern="^[0-9]{10}$"
            placeholder="Enter mobile number"
          />
          <div *ngIf="mobileInput.invalid && (mobileInput.dirty || mobileInput.touched)" class="validation-messages">
            <div *ngIf="mobileInput.errors?.required" class="warning-message">
              Phone number is required.
            </div>
            <div *ngIf="mobileInput.errors?.pattern" class="warning-message">
              Please enter a valid 10-digit phone number.
            </div>
          </div>
        </div>
      </div>

      <!-- Password Field -->
      <div class="form-item">
        <label class="field-label">Password<span class="text-danger">*</span></label>
        <div class="password-field">
          <input
            [type]="showPasswordField ? 'text' : 'password'"
            [(ngModel)]="password"
            id="runner-password"
            class="input-field"
            placeholder="Enter password"
            autocomplete="off"
          />
          <div class="show-password">
            <label class="checkbox-label">
              <input
                type="checkbox"
                (click)="showPassword()"
                class="checkbox-input"
              >
              <span class="checkbox-text">Show Password</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="btn-wrapper">
      <button class="btn btn-danger" (click)="closeModal()">CANCEL</button>
      <button class="btn btn-primary" (click)="createRunner()">SAVE</button>
    </div>
  </div>
</div>
<!-- Edit Runner Modal -->
<div *ngIf="functionality === 2" class="modal-overlay">
  <div class="add-runner">
    <div class="header">
      <span>EDIT RUNNER</span>
      <button class="close-btn" (click)="closeModal()">✕</button>
    </div>

     <!-- Custom Notification -->
     <div *ngIf="showCustomNotification" class="custom-notification">
        <span>{{ customNotificationMessage }}</span>
        <span class="close-notification" (click)="showCustomNotification = false">×</span>
    </div>

    <div class="form-container">
      <!-- Name Field -->
      <div class="form-flex">
        <div class="form-item">
          <label class="field-label">Name<span class="text-danger">*</span></label>
          <input
            [(ngModel)]="name"
            placeholder="Enter runner name"
            class="input-field"
          />
        </div>
      </div>

      <!-- Phone and Status Fields -->
      <div class="form-flex">
        <div class="form-item">
          <label class="field-label">Phone No<span class="text-danger">*</span></label>
          <input
            type="number"
            class="input-field no-spinner"
            [(ngModel)]="mobile"
            name="mobile"
            #mobileInput="ngModel"
            (keyup)="handleChange($event, 'mobile')"
            required
            pattern="^[0-9]{10}$"
            placeholder="Enter mobile number"
          />
           <div *ngIf="mobileInput.invalid && (mobileInput.dirty || mobileInput.touched)" class="validation-messages">
            <div *ngIf="mobileInput.errors?.required" class="warning-message">
              Phone number is required.
            </div>
            <div *ngIf="mobileInput.errors?.pattern" class="warning-message">
              Please enter a valid 10-digit phone number.
            </div>
          </div>
        </div>
        <div class="form-item">
          <label class="field-label">Status<span class="text-danger">*</span></label>
          <select [(ngModel)]="status" class="input-field">
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>
      </div>
         <!--Warning message for inactive user-->
         <div  class="warning-message">
            Once a runner is set to Inactive, they cannot be made active again.
         </div>
    </div>

    <div class="btn-wrapper">
      <button class="btn btn-danger" (click)="closeModal()">CANCEL</button>
      <button class="btn btn-primary" (click)="updateRunner()">SAVE</button>
    </div>
  </div>
</div>