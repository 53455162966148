<div class="dialog-container">
    <div class="dialog-header">
      <h2>Edit Store Working Hours</h2>
      <p>Set when your store is open for customers</p>
    </div>
    
    <div class="timing-card-container">
      <div *ngFor="let day of schedule; let i = index" class="day-card" [ngClass]="{'closed': !day.isOpen}">
        <div class="day-header">
          <div class="day-name">{{ day.day }}</div>
          <div class="status-toggle">
            <mat-slide-toggle 
              [checked]="day.isOpen"
              (change)="toggleStoreOpen(i)"
              color="primary"
              class="green-toggle">
              {{ day.isOpen ? 'Open' : 'Closed' }}
            </mat-slide-toggle>
          </div>
        </div>
        
        <div class="time-inputs" *ngIf="day.isOpen">
          <div class="time-field">
            <label>Opening Time</label>
            <mat-form-field appearance="outline">
              <input 
                matInput 
                type="time" 
                [value]="day.openingTime"
                (change)="updateOpeningTime(i, $event)"
                placeholder="Opening Time">
            </mat-form-field>
          </div>
          
          <div class="time-field">
            <label>Closing Time</label>
            <mat-form-field appearance="outline">
              <input 
                matInput 
                type="time" 
                [value]="day.closingTime"
                (change)="updateClosingTime(i, $event)"
                placeholder="Closing Time">
            </mat-form-field>
          </div>
        </div>
        
        <div class="closed-message" *ngIf="!day.isOpen">
          <mat-icon>event_busy</mat-icon>
          <span>Store closed on this day</span>
        </div>
      </div>
    </div>
    
    <div class="dialog-actions">
      <button mat-button type="button" (click)="cancel()">Cancel</button>
      <button 
        mat-raised-button 
        color="primary" 
        class="green-button"
        (click)="saveChanges()"
        [disabled]="isLoading">
        <span *ngIf="!isLoading">Save Changes</span>
        <span *ngIf="isLoading">Saving...</span>
      </button>
    </div>
  </div>