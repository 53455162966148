import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';
@Injectable({
  providedIn: 'root'
})
export class UpdateStoreService {
  private baseUrl: string;
  
  constructor(
    private http: HttpClient,
    private utils: UtilityService // Replace 'any' with your actual utils service type
  ) {
    this.baseUrl = environment.SERVER_URL;
  }

  // Generic method to update store details
  private updateStore<T>(storeId: string, updateType: string, data: any): Observable<T> {
    const url = `${this.baseUrl}stores/${storeId}?franchise=${environment.FRANCHISE}`;
    
    const payload = {
      updateType,
      id: storeId,
      ...data
    };

    const headers = new HttpHeaders({
      authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`
    });

    return this.http.put<T>(url, payload, { headers });
  }

  // Specific methods for each update type
  updateGST(storeId: string, gstNumber: string): Observable<any> {
    return this.updateStore(storeId, 'gst', { gst: gstNumber.trim() });
  }

  updateBankingDetails(storeId: string, bankForm: any): Observable<any> {
    return this.updateStore(storeId, 'bankingDetails', bankForm);
  }

  updateFranchiseName(storeId: string, franchiseName: string): Observable<any> {
    return this.updateStore(
      storeId, 
      'franchiseName', 
      { reg_franchise_name: franchiseName.trim() }
    );
  }
  
  // New method to update store schedule
// Fix the parameter list by removing the duplicate storeId
updateStoreSchedule(storeId: string, scheduleData: any[]): Observable<any> {
  const url = `${this.baseUrl}stores/updatestoreschedule`;
  
  const headers = new HttpHeaders({
    authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`
  });
  
  const params = {
    franchise: environment.FRANCHISE,
    store_id: storeId
  };
  
  return this.http.put<any>(url, scheduleData, { headers, params });
}
  
  // Method to get store schedule
  getStoreSchedule(storeId: string): Observable<any> {
    const url = `${this.baseUrl}api/v1/stores/storeschedule`;
    
    const headers = new HttpHeaders({
      authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`
    });
    
    const params = {
      franchise: environment.FRANCHISE,
      store_id: storeId
    };
    
    return this.http.get<any>(url, { headers, params });
  }
}