// dashboard.component.ts
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ORDER_LISTING } from '../feature/invoice/invoice-form/drycleaning-filter-constant';
import { ModalComponent } from '../modal/modal.component';
import { InvoiceService } from '../service/invoice.service';
import { UtilityService } from '../service/utility.service';
import { SearchComponent } from './search/search.component';
import { ViewInvoiceComponent } from '../view-invoice/view-invoice.component';
import { CancelModalComponent } from './cancel-modal/cancel-modal.component';
import { OrderReadyModalComponent } from './order-ready-modal/order-ready-modal.component';
import { PaymentModalComponent } from '../feature/dashboard/payment-modal/payment-modal.component';
import { SuccessModalComponent } from '../feature/dashboard/success-modal/success-modal.component';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { OrderSourceComponent } from './order-source/order-source.component';
import { DateRangeComponent } from './date-range/date-range.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    dialog: any;
    orderObj: any;
    @ViewChild(SearchComponent) searchComponent!: SearchComponent;
    @ViewChild(DateRangeComponent) dateRangeComponent: DateRangeComponent;
    @ViewChild(OrderSourceComponent) orderSourceComponent!: OrderSourceComponent;
    isNewOrderListed: boolean = true;
    isAddressExpanded: any;
    isInstructionsExpanded: boolean[] = [];
    type: 'NEW' | 'INPRO' = 'NEW';
    selectedDateOptions: string[] = [];
    customFromDate: any = '';
    customToDate: any = '';
    readonly itemsPerPage: number = 30;
    allOrders: any[] = [];
    displayedOrders: any[] = [];
    actualTotalPages: number = 1;

    public finalAmount: number;
    public drycleaningItemList = [];
    public orderListing = ORDER_LISTING;
    public selectedSources: string[] = [];
    public orders: any = null;
    public multipleBookings: any[] = [];
    public dropIcon = `${environment.assetUrl}assets/orderthrough/cross.svg`;
    public upArrow = `${environment.assetUrl}assets/images/icons/upArrow.svg`;
    public pickupIcon = `${environment.assetUrl}assets/images/icons/pickup.svg`;
    public cross = `${environment.assetUrl}assets/orderthrough/cross.png`;
    public calendar = `${environment.assetUrl}assets/orderthrough/calendar.png`;
    public qrIcon = `${environment.assetUrl}assets/orderthrough/qr.svg`;
    public tickCircle = `${environment.assetUrl}assets/images/icons/tick-circle.svg`;
    public tickSquare = `${environment.assetUrl}assets/images/icons/tick-square.svg`;
    public assets = environment.assetUrl;
    public sortQuery: string = '';
    public selectedFilter: string = '';
    orderThroughFilter: string = ''; //added to filter ordersThrough
    searchQuery: string = ''; // Move searchQuery to the parent component

    public message: any = '';
    public messageType: number = -1;
    filteredOrders: any[] = [];

    currentPage: number = 1;
    totalPages: number = 10;
    sortColumn: string | null = null;
    sortDirection: 'asc' | 'desc' = 'asc';
    currentBrand: 'uclean' | 'whitetiger' = 'uclean';

    mobileView: boolean = false;
    dateS: any;
    fromDate: any;
    toDate: any;
    selectedApplyOptions: string[] = []; // Initialize to an empty array
    dateOption: string = '';
    activeFiltersText: string = '';
    challanNumber: string | number | null = null;
    showPopup: boolean;
    final_amount : any;
    final: any;
    finalAmounts: number[];
    bookingAmounts: { bookingCode: any; finalAmount: number; }[];
    bookingAmountMap: any;
  

    constructor(
        private http: HttpClient,
        private router: Router,
        private dialogRef: MatDialog,
        private utils: UtilityService,
        private invoiceService: InvoiceService,
        private toastrService: ToastrService,
        private apiService: ApiService
    ) { }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.mobileView = event.target.outerWidth <= 820;
    }

    ngOnInit(): void {
        this.currentPage = +(localStorage.getItem('currentPage') || 1);
        this.type = (localStorage.getItem('orderType') as 'NEW' | 'INPRO') || 'NEW';
        this.isNewOrderListed = this.type === 'NEW';
        this.selectedDateOptions = JSON.parse(localStorage.getItem('selectedDateOptions') || '[]');
        this.fromDate = localStorage.getItem('fromDate') || '';
        this.toDate = localStorage.getItem('toDate') || '';
        this.selectedApplyOptions = JSON.parse(localStorage.getItem('selectedApplyOptions') || '[]');
        this.dateOption = localStorage.getItem('dateOption') || '';
        this.selectedSources = JSON.parse(localStorage.getItem('selectedSources') || '[]');
        this.orderThroughFilter = localStorage.getItem('orderThroughFilter') || '';
        this.searchQuery = localStorage.getItem('searchQuery') || '';

        this.getData();
        this.filteredOrders = this.orders;
        this.updateActiveFiltersText();
        
    }
    // In your component's TypeScript file
    displayTime(date: string | Date) {
        const dateObj = new Date(date);
        return {
            date: dateObj.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric'
            }),
            time: dateObj.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            })
        };
    }
// Component TS
toggleAddress(event: Event) {
    event.stopPropagation(); // Prevent event bubbling
    this.isAddressExpanded = !this.isAddressExpanded;
    console.log(`Address expansion toggled: ${this.isAddressExpanded}`);
  }
  
  // Function to get the shortened address (first 5 words)
  getShortAddress(address: string): string {
    if (!address) return '';
    const words = address.split(' ');
    if (words.length <= 5) return address;
    return words.slice(0, 5).join(' ') + '...';
  }
 // In your component class
toggleServicePopup(challanNumber: string | number) { // More specific type than 'any'
    console.log(this.challanNumber);
    this.showPopup = true;
    this.challanNumber = challanNumber; // Store the actual challan number
}

closeServicePopup() {
    this.showPopup = false;
    this.challanNumber = null;
}
onPopupClick(event: MouseEvent) {
    // Check if the click was directly on the popup background (not its children)
    if ((event.target as HTMLElement).classList.contains('popup')) {
      this.closeServicePopup();
    }
  }

    sortAscending(column: string) {
        this.sortDirection = this.sortColumn === column && this.sortDirection === 'asc' ? 'desc' : 'asc';
        this.sortColumn = column;

        this.orders.sort((a, b) => {
            if (a[column] < b[column]) {
                return this.sortDirection === 'asc' ? -1 : 1;
            }
            if (a[column] > b[column]) {
                return this.sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    getSortIcon(column: string): string {
        if (this.sortColumn !== column) {
            return '↕'; // or use any default icon
        }
        return this.sortDirection === 'asc' ? '↑' : '↓';
    }

    onPageChange(newPage: number) {
        this.currentPage = newPage;
        localStorage.setItem('currentPage', String(this.currentPage));
        this.getData();
    }


    convertToDate(date: any) {
        return new Date() > new Date(date);
    }

    handleDateChange(dateRange: { fromDate: any, toDate: any, dateS: string }) {
        if (dateRange.fromDate !== '' && dateRange.toDate !== '' && dateRange.dateS !== 'Select') {
            this.getData();
        }
    }

    getStepClass(currentStatus: number, stepStatus: number): string {
        if (currentStatus > stepStatus) {
            return 'completed';
        } else if (currentStatus === stepStatus) {
            return 'active';
        }
        return '';
    }

    selectAll() {
        console.log(" selectAll is working");
        if (this.multipleBookings?.length !== 0) {
            this.multipleBookings = [];
        } else {
            this.orders.forEach((e: any) => {
                this.multipleBookings.push(e);
            });
        }
        console.log(this.multipleBookings);
    }


    printBarCode(id: number) {
        this.router.navigateByUrl(`barcode/${id}`);
    }
    renderIcons = (num: number) => {
        switch (num) {
            case 1:
                return `${environment.assetUrl}assets/orderthrough/walkinicon.svg`;
            case 2:
                return `${environment.assetUrl}assets/orderthrough/customercareicon.svg`;
            case 3:
                return `${environment.assetUrl}assets/orderthrough/storeicon.svg`;
            case 4:
                return `${environment.assetUrl}assets/orderthrough/mobileicon.svg`; // Assuming mobile icon is appropriate for case 4.
            case 5:
                return `${environment.assetUrl}assets/orderthrough/webicon.svg`;
            case 6:
                return `${environment.assetUrl}assets/orderthrough/whatsappicon.svg`;
            default:
                console.warn(`Unexpected orderthrough_id: ${num}`);
                return `${environment.assetUrl}assets/orderthrough/walkinicon.svg`;
        }
    }



    openConfirmation(
        id: number | Array<number>,
        postURL: string,
        title: string,
        body: { status: number },
        del: number = 0,
        modalComponent: Type<any> // Add this parameter
    ) {
        const modal = this.dialogRef.open(modalComponent, {
            data: {
                bookingId: id,
                messageToDisplay: title,
                postURL,
                body,
                delete: del,
            },
        });
        modal.componentInstance.trigger.subscribe((data) => {
            this.getData();
        });
    }

    updateOrder(orderId: number | Array<number>) {
        this.openConfirmation(
            orderId,
            `bookings/updateStatus/${orderId}?franchise=${environment.FRANCHISE}`,
            'Are you sure you want to mark this order as Ready?',
            {
                status: 4,
            },
            0,
            OrderReadyModalComponent
        );
    }

    handleCancel(id: number) {
        this.openConfirmation(
            id,
            `bookings/updateStatus/${id}?franchise=${environment.FRANCHISE}`,
            'Are you sure you want to cancel this order?',
            {
                status: 0,
            },
            1,
            CancelModalComponent
        );
    }

// First, modify the complete method to find the correct order and its final amount
complete(orderId: number) {
    
    // Find the order in allOrders array
    const order = this.allOrders.find(order => order.id === orderId);
    
    if (order) {
        // Get the final amount for this specific order
        const finalAmount = parseFloat(order.final_amount) || 0;
        
        
        const dialogRef = this.openPaymentModal(orderId, finalAmount);
        
        dialogRef.afterClosed().subscribe(result => {
            console.log("Payment modal closed. Result:", result);
            if (result && result.statusResponse && result.statusResponse.status === 'success') {
                console.log("Order status update response:", result.statusResponse);
                this.updateOrderInList(orderId);
            } else {
                console.warn("Status not updated to 7, check statusResponse.");
            }
        });
    } else {
        console.error("Order not found for ID:", orderId);
        this.toastrService.error("Order not found");
    }
}

// Update the openPaymentModal method to handle the correct final amount
openPaymentModal(orderId: number, finalAmount: number) {
    return this.dialogRef.open(PaymentModalComponent, {
        width: '30%',
        data: { 
            bookingId: orderId,
            finalAmount: finalAmount // This will now be the correct final amount for the specific order
        }
    });
}

// Update the updateOrderInList method to maintain consistency
updateOrderInList(orderId: number) {
    console.log("Updating order status in the list for orderId:", orderId);
    const orderIndex = this.allOrders.findIndex(order => order.id === orderId);
    
    if (orderIndex !== -1) {
        this.allOrders[orderIndex].status = 7;
        console.log("Order status set to 7 for order at index:", orderIndex);
        
        // Update both allOrders and displayed orders
        this.updateDisplayedOrders();
        
        // Store the updated order
        this.orderObj = this.allOrders[orderIndex];
        console.log("Updated orderObj:", this.orderObj);
    } else {
        console.warn("Order not found in the list.");
        this.toastrService.warning("Order not found in the list");
    }
}


    showSuccessModal() {
        this.dialog.open(SuccessModalComponent, {
            width: '30%'
        });
    }

    viewInvoice = (id: number) => {
        this.invoiceService.previewInvoice(id).subscribe((data) => {
            const reader = new FileReader();
            reader.readAsText(data);

            reader.onloadend = () => {
                this.dialogRef.open(ViewInvoiceComponent, {
                    data: reader.result!.toString(),
                    width: '230mm',
                    height: '230mm',
                    panelClass: 'a4-dialog'
                });
            };
        });
    }

    openModal(
        id: number | Array<number>,
        fetchURL: string,
        postURL: string,
        title: string
    ) {
        const modal = this.dialogRef.open(ModalComponent, {
            data: {
                fetchURL,
                postURL,
                bookingId: Array.isArray(id) ? id : id,
                title,
            },
        });

        modal.componentInstance.trigger.subscribe((data: any) => {
            this.multipleBookings = [];
            this.getData();
        });
    }

    assignPickup(id: number | number[]) {
        this.openModal(
            id,
            'runners?page=1&status=1',
            'bookings/assignPickup',
            'Assign Pickup'
        );
    }

    isSelectedInMultiple(id: number) {
        const isSelected = this.multipleBookings.some(booking => booking.id === id);
        return isSelected;
    }


    handleAddOrRemoveFromBookings(event: any, booking: any) {
        console.log("handleAddOrRemoveFromBookings is Working");

        // Log the current state of multipleBookings
        console.log("Current multipleBookings:", this.multipleBookings);

        const index = this.multipleBookings.findIndex((e: any) => e.id === booking.id);

        if (index === -1) {
            this.multipleBookings.push(booking);
            console.log(`Added booking with ID ${booking.id}`);
        } else {
            this.multipleBookings.splice(index, 1);
            console.log(`Removed booking with ID ${booking.id}`);
        }

        // Log the updated state of multipleBookings
        console.log("Updated multipleBookings:", this.multipleBookings);
    }
    onSearchPerformed(searchResult: { query: string, orders: any[], totalPages: number }) {
        this.searchQuery = searchResult.query;
        localStorage.setItem('searchQuery', this.searchQuery);
        this.currentPage = 1; // Reset to first page on new search
        localStorage.setItem('currentPage', String(this.currentPage));
        this.getData();
    }

    changeOrderType(newType: 'NEW' | 'INPRO') {
        this.type = newType;
        localStorage.setItem('orderType', this.type);
        this.isNewOrderListed = newType === 'NEW';
        if (this.searchComponent) {
            // Check if searchComponent is defined before accessing its properties
            if (this.searchComponent && this.searchComponent.searchQuery) {
                this.searchQuery = this.searchComponent.searchQuery;
            }

        }
        this.getData();
    }

    onSourcesChanged(selectedSources: string[]): void {
        this.selectedSources = selectedSources;
        localStorage.setItem('selectedSources', JSON.stringify(this.selectedSources));
        // Convert selectedSources array to a comma-separated string of IDs
        this.orderThroughFilter = this.selectedSources.map(source => {
            switch (source) {
                case 'Walk-In': return '1';
                case 'Customer Care': return '2';
                case 'Store': return '3';
                case 'Mobile App': return '4';
                case 'Website': return '5';
                case 'Whatsapp': return '6';
                default: return ''; // Handle unexpected values
            }
        }).filter(id => id !== '').join(','); // Remove empty strings and join
        localStorage.setItem('orderThroughFilter', this.orderThroughFilter);
        this.getData();
    }
    onDateRangeChange(dateRange: { fromDate: any, toDate: any, selectedOptions: string[], selectedApplyOptions: string[], dateOption: string }) {
        console.log('Date range changed:', dateRange);
        this.selectedDateOptions = dateRange.selectedOptions;
        localStorage.setItem('selectedDateOptions', JSON.stringify(this.selectedDateOptions));
        this.fromDate = dateRange.fromDate;
        localStorage.setItem('fromDate', this.fromDate);
        this.toDate = dateRange.toDate;
        localStorage.setItem('toDate', this.toDate);
        this.selectedApplyOptions = dateRange.selectedApplyOptions;
        localStorage.setItem('selectedApplyOptions', JSON.stringify(this.selectedApplyOptions));
        this.dateOption = dateRange.dateOption; // <-- Access the dateOption from the emitted event
        localStorage.setItem('dateOption', this.dateOption);
        console.log('Selected date options:', this.selectedDateOptions);
        console.log('Custom date range:', this.fromDate, 'to', this.toDate);
        console.log('Selected apply options:', this.selectedApplyOptions);
        this.getData();
    }
    toggleAddressExpansion(index: number) {
        this.isAddressExpanded[index] = !this.isAddressExpanded[index];
    }

    toggleInstructionsExpansion(index: number) {
        this.isInstructionsExpanded[index] = !this.isInstructionsExpanded[index];
    }
    resetFilters(): void {
        console.log('Resetting filters');

        // Reset date-related filters
        this.selectedDateOptions = [];
        localStorage.removeItem('selectedDateOptions');
        this.fromDate = '';
        localStorage.removeItem('fromDate');
        this.toDate = '';
        localStorage.removeItem('toDate');
        this.selectedApplyOptions = [];
        localStorage.removeItem('selectedApplyOptions');
        this.dateOption = '';
        localStorage.removeItem('dateOption');

        // Reset source filter
        this.selectedSources = [];
        localStorage.removeItem('selectedSources');
        this.orderThroughFilter = '';
        localStorage.removeItem('orderThroughFilter');

        // Reset search query
        this.searchQuery = '';
        localStorage.removeItem('searchQuery');

        // Reset pagination
        this.currentPage = 1;
        localStorage.setItem('currentPage', String(this.currentPage));

        //  NEW: Call reset methods on child components
        if (this.searchComponent) {
            this.searchComponent.resetSearchInput();
        }
        if (this.orderSourceComponent) {
            this.orderSourceComponent.resetOrderSources();
        }
        if (this.dateRangeComponent) {
            this.dateRangeComponent.resetDateRange();
        }

        // Any other filters you might have can be reset here.

        // Refresh data with default filters
        this.getData();
    }

    getData() {
        console.log('------ Starting getData() ------');
        
        this.apiService.getBookingsUnified(
            1,
            this.type,
            this.sortQuery,
            this.selectedFilter,
            this.dateS,
            this.fromDate,
            this.toDate,
            this.selectedApplyOptions,
            this.orderThroughFilter,
            this.searchQuery,
            this.dateOption
        ).subscribe({
            next: (response: any) => {
                console.log('Raw API response:', response);
                
                // Store all orders
                this.allOrders = response.data;
                
                // Create array of booking code and final amount pairs
                this.bookingAmounts = this.allOrders.map(order => ({
                    bookingCode: order.booking_code,
                    finalAmount: parseFloat(order.final_amount) || 0
                }));
                
                // Alternatively, create an object mapping if you prefer key-value pairs
                this.bookingAmountMap = this.allOrders.reduce((map, order) => {
                    map[order.booking_code] = parseFloat(order.final_amount) || 0;
                    return map;
                }, {});
                
                // Calculate total pages based on actual data length
                this.actualTotalPages = Math.ceil(this.allOrders.length / this.itemsPerPage);
                this.totalPages = this.actualTotalPages;
                
                // Update displayed orders based on current page
                this.updateDisplayedOrders();
                
                // Initialize expansion arrays
                this.isAddressExpanded = new Array(this.allOrders.length).fill(false);
                this.isInstructionsExpanded = new Array(this.allOrders.length).fill(false);
                
                // Sort the orders
                this.sortOrders();
                
                this.updateActiveFiltersText();
                
                // Log the mappings for verification
                console.log('Booking amounts:', this.bookingAmounts);
                console.log('Booking amount map:', this.bookingAmountMap);
            },
            error: (error) => {
                console.error('API Error:', error);
            }
        });
    }

    updateDisplayedOrders() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        this.displayedOrders = this.allOrders.slice(startIndex, endIndex);
        this.orders = this.displayedOrders; // Update the orders property for existing template binding
    }

    updatePageNumber(event: { page: number }) {
        this.currentPage = event.page;
        localStorage.setItem('currentPage', String(this.currentPage));
        this.updateDisplayedOrders();
    }

    sortOrders() {
        if (this.type === 'NEW') {
            this.allOrders.sort((a: any, b: any) => {
                const aHasPickup = a.pickup_assigned_to !== null && a.pickup_assigned_to !== undefined;
                const bHasPickup = b.pickup_assigned_to !== null && b.pickup_assigned_to !== undefined;

                if (aHasPickup && !bHasPickup) return -1;
                if (!aHasPickup && bHasPickup) return 1;

                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
        } else if (this.type === 'INPRO') {
            this.allOrders.sort((a: any, b: any) => {
                const aDate = a.updated_at ? new Date(a.updated_at) : new Date(a.created_at);
                const bDate = b.updated_at ? new Date(b.updated_at) : new Date(b.created_at);

                return bDate.getTime() - aDate.getTime();
            });
        }

        // Update displayed orders after sorting
        this.updateDisplayedOrders();
    }


    formatTime = (date: string, time: string) => {
        if (!date || date === '') {
            return '';
        }
        if (time) {
            return moment(date).format('DD/MM/YYYY') + ' ' + time;
        } else {
            return moment(date).format('DD/MM/YYYY');
        }
    }

    formatAddress = (address: string) => address.replace(/, ,/g, ',');

    gotoUrl = (url: string) => {
        this.router.navigateByUrl(url);
    }

    typeOfMessage(type: string | string[]) {
        if (Array.isArray(type)) {
            return true;
        } else {
            return false;
        }
    }



    assignDrop = (id: number | number[]) => {
        this.openModal(id, 'runners?page=1&status=1', `bookings/assignDrop?franchise=${environment.FRANCHISE}`, 'Assign Drop');
    }

    updateActiveFiltersText() {
        let filters: string[] = [];

        if (this.searchQuery) {
            filters.push(`Search: ${this.searchQuery}`);
        }

        if (this.selectedSources && this.selectedSources.length > 0) {
            filters.push(`Order Sources: ${this.selectedSources.join(', ')}`);
        }
        if (this.selectedApplyOptions && this.selectedApplyOptions.length > 0) {
          filters.push(`Date filter is  applied on: ${this.selectedApplyOptions.join(', ')}`);
      }

        if (this.selectedDateOptions && this.selectedDateOptions.length > 0) {
            filters.push(`Date Options: ${this.selectedDateOptions.join(', ')}`);
        }
       

        if (this.fromDate && this.toDate) {
            filters.push(`Date Range: ${this.formatTime(this.fromDate, '')} - ${this.formatTime(this.toDate, '')}`);
        }

        this.activeFiltersText = filters.length > 0 ? `Active Filters: ${filters.join(' | ')}` : '';
    }
}