import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-customer-directory-date',
  templateUrl: './customer-directory-date.component.html',
  styleUrls: ['./customer-directory-date.component.scss']
})
export class CustomerDirectoryDateComponent implements OnInit {
  @Output() dateFilterChange = new EventEmitter<any>();

  isOpen: boolean = false;
  isLoading: boolean = false;

  filter: any = {
    applyOn: '',
    dateOption: '',
    fromDate: '',
    toDate: ''
  };

  applyOnOptions: string[] = ['Customer Since', 'Last Order Date'];
  dateOptions: string[] = ['Today', 'Last 7 Days', 'Last 30 Days', 'Last Year', 'Custom'];

  constructor() { }

  ngOnInit(): void {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  selectApplyOn(option: string) {
    this.filter = { ...this.filter, applyOn: option };
  }

  selectDateOption(option: string) {
    this.filter = { ...this.filter, dateOption: option };
  }

  onFromDateChange(value: string) {
    this.filter = { ...this.filter, fromDate: value };
  }

  onToDateChange(value: string) {
    this.filter = { ...this.filter, toDate: value };
  }

  applyFilter() {
    this.dateFilterChange.emit(this.filter);
    this.toggleDropdown(); // Close the dropdown after applying the filter
  }

  clearFilter() {
    this.filter = {
      applyOn: '',
      dateOption: '',
      fromDate: '',
      toDate: ''
    };
    this.dateFilterChange.emit(this.filter); // Emit the cleared filter
    this.toggleDropdown(); // Close the dropdown after clearing the filter
  }
}