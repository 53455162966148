import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-daily-sales-report',
  templateUrl: './daily-sales-report.component.html',
  styleUrls: ['./daily-sales-report.component.scss'],
})
export class DailySalesReportComponent implements OnInit {
  from: string = '';
  to: string = '';
  message: string = '';
  selectedReport: string = 'DSR';
  messageType: number = 0;
  data;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    // Initialize component
  }

  onDateRangeSelected(dateRange: { from: string, to: string }) {
    this.from = dateRange.from;
    this.to = dateRange.to;
    this.generateReport();
  }

  generateReport() {
    this.data = null;
    this.apiService.generateDSR(this.from, this.to).subscribe(
      (response: any) => {
        this.data = this.processData(response.data);
        console.log(this.data);
      },
      (error) => {
        console.error('Error generating DSR:', error);
      }
    );
  }

  generateCollectionReport() {
    console.log('Generating Collection Report for:', this.from, this.to); // Log the date range
  
    this.apiService.generateCollectionReport(this.from, this.to).subscribe(
      (response: any) => {
        console.log('Raw Collection Report Response:', response); // Log raw response
        this.data = this.processCollectionData(response.data);
        console.log('Processed Collection Data:', this.data); // Log processed data
      },
      (error) => {
        console.error('Error generating Collection Report:', error);
      }
    );
  }
  
  
  processCollectionData(rawData: any): any {
    console.log('Processing Raw Collection Data:', rawData); // Log raw data before processing
    
    return {
      dailySummary: rawData.dailySummary.map(item => ({
        ...item,
        date: new Date(item.date).toISOString().split('T')[0]
      })),
      overallSummary: rawData.overallSummary
    };
  }
  

  processData(rawData: any[]): any[] {
    return rawData.map(item => ({
      ...item,
      odate: new Date(item.odate).toISOString().split('T')[0]
    }));
  }
  generateQuantityReport() {
    console.log('Generating Quantity Report for:', this.from, this.to); // Log the date range

    this.apiService.generateQuantityReport(this.from, this.to).subscribe(
      (response: any) => {
        console.log('Raw Quantity Report Response:', response); // Log raw response
        this.data = this.processQuantityData(response.data);
        console.log('Processed Quantity Data:', this.data); // Log processed data
      },
      (error) => {
        console.error('Error generating Quantity Report:', error);
      }
    );
  }

  processQuantityData(rawData: any): any {
    console.log('Processing Raw Quantity Data:', rawData); // Log raw data before processing
  
    return rawData.map(item => ({
      date: new Date(item.odate).toISOString().split('T')[0],  // Using 'odate' as the date field
      dc_quantity: item.dc_sale_quantity,
      pi_kg_quantity_pcs: item.premium_laundry_quantity,      
      pi_kg_quantity_kg: item.premium_laundry_kg_quantity,    
      laundry_quantity_pcs: item.laundry_wash_iron_quantity,  
      laundry_quantity_kg: item.laundry_wash_fold_quantity,   
      sp_quantity: item.steam_press_quantity,               
      si_quantity: item.standard_iron_quantity                      
    }));
  }
  

  calculateGST(item: any): number {
    return this.calculateNetAmount(item) * 0.18; // 18% GST
  }

  calculateTotal(item: any): number {
    return this.calculateNetAmount(item) + this.calculateGST(item);
  }

  getParentTradeName(id: number): string {
    switch (id) {
      case 1: return "Dry cleaning";
      case 2: case 9: return "Premium Laundry";
      case 3: case 10: return "Steam Press";
      case 4: return "Laundry - Wash & Fold";
      case 5: return "Laundry - Wash & Iron";
      case 6: return "Home Service";
      case 8: return "Vacuum Iron";
      default: return "Unknown";
    }
  }
  calculateNetAmount(item: any): number {
    return (
      item.dc_sale +
      item.premium_laundry +
      item.laundry_wash_fold +
      item.laundry_wash_iron +
      item.steam_press +
      item.standard_iron +
      item.addon_1 +
      item.addon_2 +
      item.addon_3 +
      item.express_sale -
      item.discount
    );
  }
  showReport(reportType: string) {
    this.selectedReport = reportType;

    switch (reportType) {
      case 'DSR':
        this.generateReport(); // DSR Report
        break;
      case 'Collection':
        this.generateCollectionReport(); // Collection Report
        break;
      case 'Quantity':
        this.generateQuantityReport(); // Quantity Report
        break;
      default:
        console.error('Unknown report type selected');
    }
  }

}