<div class="gst-dialog-container">
  <div class="dialog-header">
    <h2>Edit GST Details</h2>
    <button class="close-button" (click)="onCancel()">
      <span>×</span>
    </button>
  </div>

  <div class="dialog-content">
    <div class="input-group">
      <label>GST Number</label>
      <input
        [(ngModel)]="gstNumber"
        (input)="onGSTInput()"
        placeholder="Enter GST Number (e.g., 29ABCDE1234F1Z5)"
        [class.invalid]="validationErrors.length > 0"
        maxlength="15"
      >
      <div class="validation-messages" *ngIf="validationErrors.length > 0">
        <div class="error-message" *ngFor="let error of validationErrors">
          {{ error }}
        </div>
      </div>
    </div>


    <div class="current-gst" *ngIf="data.currentGst">
      <span class="label">Current GST:</span>
      <span class="value">{{data.currentGst}}</span>
    </div>
  </div>

  <div class="dialog-footer">
    <button
      class="cancel-button"
      (click)="onCancel()"
      [disabled]="isSubmitting"
    >
      Cancel
    </button>
    <button
      class="submit-button"
      (click)="onSubmit()"
      [disabled]="isSubmitting || validationErrors.length > 0 || !gstNumber.trim()"
    >
      <span class="button-content">
        <span class="button-text">{{ isSubmitting ? 'Updating...' : 'Update GST' }}</span>
        <div class="spinner" *ngIf="isSubmitting"></div>
      </span>
    </button>
  </div>
</div>