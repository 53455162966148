import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UpdateStoreService } from '../service/updatestore.service';

interface WeekDay {
  id?: number;
  day: string;
  isOpen: boolean;
  openingTime: string;
  closingTime: string;
}

@Component({
  selector: 'app-store-timing-edit-dialog',
  templateUrl: './store-timing-edit-dialog.component.html',
  styleUrls: ['./store-timing-edit-dialog.component.scss']
})
export class StoreTimingEditDialogComponent implements OnInit {
  weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  schedule: WeekDay[] = [];
  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<StoreTimingEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { storeId: string, franchise: string, schedule: WeekDay[] },
    private storeService: UpdateStoreService,
  ) {}

  ngOnInit(): void {
    this.initializeSchedule();
  }

  initializeSchedule(): void {
    // Initialize schedule with existing data or defaults
    this.schedule = this.weekDays.map(day => {
      const existingDay = this.data.schedule?.find(d => d.day === day);
      return {
        day: day,
        isOpen: existingDay ? existingDay.isOpen : true,
        openingTime: existingDay ? existingDay.openingTime : '09:00',
        closingTime: existingDay ? existingDay.closingTime : '18:00'
      };
    });
  }

  toggleStoreOpen(index: number): void {
    this.schedule[index].isOpen = !this.schedule[index].isOpen;
  }

  updateOpeningTime(index: number, event: any): void {
    const newOpeningTime = event.target.value;
    this.schedule[index].openingTime = newOpeningTime;

    // Ensure closing time is not earlier than opening time
    if (this.isClosingTimeBeforeOpeningTime(this.schedule[index].openingTime, this.schedule[index].closingTime)) {
      this.schedule[index].closingTime = newOpeningTime; // Set closing time equal to opening time
    }
  }

  updateClosingTime(index: number, event: any): void {
    const newClosingTime = event.target.value;
    this.schedule[index].closingTime = newClosingTime;

    // Ensure closing time is not earlier than opening time
    if (this.isClosingTimeBeforeOpeningTime(this.schedule[index].openingTime, this.schedule[index].closingTime)) {
      this.schedule[index].openingTime = newClosingTime; // Or revert back to previous opening Time if you want
      // you can also set it equal opening time if that suits your needs
    }
  }

  // Helper function to compare times
  private isClosingTimeBeforeOpeningTime(openingTime: string, closingTime: string): boolean {
    if (!openingTime || !closingTime) {
      return false; // Or handle the null/undefined case as you see fit
    }

    const openingTimeDate = new Date(`01/01/2000 ${openingTime}`);
    const closingTimeDate = new Date(`01/01/2000 ${closingTime}`);

    return closingTimeDate <= openingTimeDate;
  }

  saveChanges(): void {
    this.isLoading = true;

    this.storeService.updateStoreSchedule(this.data.storeId, this.schedule)
      .subscribe({
        next: (response) => {
          this.dialogRef.close(this.schedule);
          this.isLoading = false;
        },
        error: (error) => {
          console.error('Error updating store timing:', error);
          this.isLoading = false;
        }
      });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}