<h4 mat-dialog-title class="text-center">Payment Result</h4>
<mat-dialog-content>
  <div class="content-container">
    <img src='../../../../assets/images/congrats.png' alt="congrats">
    <h4><b>Congratulations!</b></h4>
    <h4><b>Order is marked delivered</b></h4>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button (click)="onClose()">Close</button>
</mat-dialog-actions>