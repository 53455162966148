<div class="date-range-container">
  <div class="dropdown-toggle" (click)="toggleDropdown()">
    <img [src]="calendarIcon" alt="calendar">
    <span class="text-content">{{ getSelectedOptionsText() }}</span>
    <img [src]="dropdownIcon" alt="dropdown">
  </div>

  <div *ngIf="isOpen" class="dropdown-content">
    <!-- Apply On Section (Now visible for all types) -->
    <div class="apply-on-section">
      <h4>Apply On</h4>
      <div *ngFor="let option of applyOnOptions">
        <label class="radio-container">
          <input type="radio"
                 name="applyOn"
                 [value]="option"
                 (change)="onApplyOptionChange(option)"
                 [checked]="selectedApplyOptions[0] === option">
          <span class="radio-checkmark"></span>
          {{ option }}
        </label>
      </div>
      <hr>
    </div>

    <!-- Date Options -->
    <div *ngFor="let option of dateOptions">
      <label class="checkbox-container">
        <input type="checkbox"
               [value]="option"
               (change)="onOptionChange(option)"
               [checked]="selectedOptions[0] === option">
        <span class="checkmark"></span>
        {{ option }}
      </label>
    </div>

    <!-- Custom Date Range Section -->
    <div *ngIf="selectedOptions[0] === 'Custom'" class="custom-date-range">
      <div class="date-input">
        <label>From</label>
        <input type="date" [(ngModel)]="fromDate" placeholder="DD-MM-YYYY">
      </div>
      <div class="date-input">
        <label>To</label>
        <input type="date" [(ngModel)]="toDate" placeholder="DD-MM-YYYY">
      </div>
      <div class="button-group">
        <button class="save-btn"
                (click)="saveCustomDate()"
                [disabled]="!fromDate || !toDate">
          SAVE
        </button>
        <button class="cancel-btn" (click)="cancelCustomDate()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>