import { Input, EventEmitter, Output, Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {

  ngOnInit(): void {
    console.log('Pagination Component Initialized:', {
      totalPages: this.totalPages,
      currentPage: this.currentPage
    });
  }

  @Input() totalPages: number = 0;
  @Input() currentPage: number = 1;
  @Output() page = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalPages']) {
      console.log('Pagination Component - totalPages changed:', this.totalPages);
    }
    if (changes['currentPage']) {
      console.log('Pagination Component - currentPage changed:', this.currentPage);
    }
  }

  updateCurrentPage(pageNumber: number) {
    console.log('Pagination Component - Update Current Page:', {
      pageNumber,
      totalPages: this.totalPages,
      currentPageBeforeUpdate: this.currentPage
    });

    if (pageNumber === -1 || pageNumber === 0 || pageNumber > this.totalPages) {
      console.log('Pagination Component - Invalid page number, returning');
      return;
    }

    this.currentPage = pageNumber;
    console.log('Pagination Component - Current Page After Update:', this.currentPage);
    this.emitData();
  }

  emitData() {
    console.log('Pagination Component - Emitting page data:', this.currentPage);
    this.page.emit({
      page: this.currentPage,
    });
  }

  getPageNumbers(): number[] {
    let pages: number[] = [];

    if (this.totalPages <= 5) {
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);

      if (this.currentPage <= 3) {
        for (let i = 2; i <= 5; i++) {
          pages.push(i);
        }
        pages.push(-1);
        pages.push(this.totalPages);
      } else if (this.currentPage >= this.totalPages - 2) {
        pages.push(-1);
        for (let i = this.totalPages - 4; i <= this.totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(-1);
        for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
          pages.push(i);
        }
        pages.push(-1);
        pages.push(this.totalPages);
      }
    }

    return pages;
  }
}