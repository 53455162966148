<section class="order-flow-section">
  <!-- Search and Header Section -->
  <div class="top-section">
    <div class="search-container">
      <input
        type="text"
        class="search-input"
        placeholder="Search by Mobile No., Name, Booking Code..."
        (keyup)="handleSearch($event)"
      />
      <div class="filter-group">
        <app-archive-order-source
        (sourcesChanged)="onSourcesChanged($event)"
      ></app-archive-order-source>

      <app-date-archive (dateChange)="handleDateChange($event)"></app-date-archive>
      </div>
    </div>
  </div>

  <!-- Table Section -->
  <div class="table-wrapper">
    <table class="orders-table">
      <thead>
        <tr>

          <th (click)="handleSort('order.booking_code')" class="sortable-header">
            Order
            <span class="sort-icon">{{ getSortIcon('order.booking_code') }}</span>
          </th>
          <th (click)="handleSort('order.pick_boy_assign_at')" class="sortable-header">
            Pickup
            <span class="sort-icon">{{ getSortIcon('order.pick_boy_assign_at') }}</span>
          </th>
          <th (click)="handleSort('order.delivered_at')" class="sortable-header">
            Delivery
            <span class="sort-icon">{{ getSortIcon('order.delivered_at') }}</span>
          </th>
          <th>Customer</th>
          <th>Address</th>
          <th>Payment</th>
          <th>Instructions</th>
          <th (click)="handleSort('order.status')" class="sortable-header">
            Status
            <span class="sort-icon">{{ getSortIcon('order.status') }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of orders">

          <td class="order-col">
            <div class="order-code">
              <img [src]="renderIcons(order.orderthrough_id)" alt="order type" />
              <span  style="cursor: pointer;"  (click)="viewInvoice(order?.id)">{{ order.booking_code }}</span>
            </div>
          </td>
          <td>{{ displayTime(order.pick_boy_assign_at) }}</td>
          <td>{{ displayTime(order.delivered_at) }}</td>
          <td class="customer-col">
            <div class="customer-name">{{ order.name }}</div>
            <div class="customer-phone">{{ order.mobile }}</div>
          </td>
          <td class="address-col">{{ order.address }}</td>
          <td class="payment-col">
            <div *ngIf="!order.payment_details" class="incomplete-payment" (click)="complete(order.id)">
              
                <img [src]="error" class="payment-icon">
              
              <span class="payment-pending-text">Payment Pending</span>
            </div>
            <div *ngIf="order.payment_details" class="payment-details-available" (click)="showPaymentDetails(order)">
              View Payment Details
            </div>
          </td>
          <td>{{ order.suggestion || 'Handle with care' }}</td>

          <td class="status-col">
            <span [class]="'status-badge ' + (order.status === 7 ? 'delivered' : 'cancelled')">
              {{ renderStatus(order.status) }}
            </span>
            <div class="status-date">{{ displayTime(order.delivered_at) }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <br />
  <div style="display: flex; justify-content: flex-end">
    <p>
      Showing results {{ (currentPage - 1) * 30 }} to {{ currentPage * 30 }}
    </p>
  </div>
  <br />
  <div class="pagination-container">
    <app-pagination
      (page)="updatePageNumber($event)"
      [totalPages]="totalPages"
    ></app-pagination>
  </div>

  <!-- Payment Details Modal (Hidden by default) -->
  <div class="payment-modal" *ngIf="selectedOrder && showModal">
    <div class="modal-content">
      <span class="close-button" (click)="closePaymentModal()">×</span>
      <h2>Payment Details for Order {{ selectedOrder.booking_code }}</h2>
      <div *ngIf="selectedOrder.payment_details">
        <div class="payment-item" *ngFor="let payment of getPaymentDetailsArray(selectedOrder.payment_details)">
          <div><strong>Mode:</strong> {{ renderPaymentMode(payment.payment_mode) }}</div>
          <div><strong>Amount:</strong> {{ payment.payment_amount }}</div>
          <div><strong>Date:</strong> {{ displayTime(payment.created_at) }}</div>
        </div>
      </div>
      <div *ngIf="!selectedOrder.payment_details">
        <p>No payment details available.</p>
      </div>
    </div>
  </div>
</section>