import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-page-index',
  templateUrl: './page-index.component.html',
  styleUrls: ['./page-index.component.scss']
})
export class PageIndexComponent {
  @Input() currentPage: number = 1;
  @Input() totalPages: number = 1;
  @Input() orders: any = null;
  @Output() pageChange = new EventEmitter<{ page: number }>();
  currentBrand: 'uclean' | 'whitetiger' = 'uclean';

  private scrollToTop() {
    // Get the dashboard element - adjust the selector if needed
    const dashboardElement = document.querySelector('.dashboard-heading');
    if (dashboardElement) {
      dashboardElement.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    } else {
      // Fallback to scrolling to the top of the window
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  onPrevious() {
    if (this.currentPage > 1) {
      this.updateCurrentPage(this.currentPage - 1);
      this.scrollToTop();
    }
  }

  onNext() {
    if (this.currentPage < this.totalPages) {
      this.updateCurrentPage(this.currentPage + 1);
      this.scrollToTop();
    }
  }

  updateCurrentPage(page: number) {
    if (page !== -1 && page !== this.currentPage) {
      this.pageChange.emit({ page: page });
      this.scrollToTop();
    }
  }

  getPageNumbers(): number[] {
    const pageNumbers: number[] = [];
    if (this.totalPages <= 5) {
      for (let i = 1; i <= this.totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      if (this.currentPage > 3) {
        pageNumbers.push(-1);
      }
      const start = Math.max(2, this.currentPage - 1);
      const end = Math.min(this.totalPages - 1, this.currentPage + 1);
      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }
      if (this.currentPage < this.totalPages - 2) {
        pageNumbers.push(-1);
      }
    }
    return pageNumbers;
  }
}