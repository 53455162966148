<header class="header-section" *ngIf="currentRoute !== '/login'">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="tab-menu">
          <div class="logo-section">
            <img class="logo-image"  alt="logo" (click)="goToHome()" />
          </div>
          <div>
            <div (click)="toggleMenu()"><mat-icon>menu</mat-icon></div>
          </div>
        </div>
        <div class="overlay" [ngClass]="{ active: showMenu }">
          <div class="navbar-item-container-tab">
            <div class="logo-section menu-tab-head">
              <img src="{{ logoSrc }}" alt="logo" (click)="goToHome()" />
              <div class="cross-container">
                <div (click)="toggleMenu()"><mat-icon>clear</mat-icon></div>
              </div>
            </div>
            <!-- <div class="navigation-item" (click)="goToUrl('order-flow/step1')">
              Orders
            </div> -->
            <div class="tabmenuTop">
              <div class="navigation-item" (click)="goToUrl('dashboard')">
                Dashboard
              </div>
              <div
                class="navigation-item"
                (click)="goToUrl('customer-directory')"
              >
                Customers
              </div>
              <div class="navigation-item" (click)="goToUrl('price')">
                Price
              </div>
              <div class="navigation-item" (click)="goToUrl('coupons-table')">
                Coupons
              </div>
              <!-- <div class="navigation-item" (click)="goToUrl('packages-table')">
                Packages
              </div> -->
              <button
                class="sort-button navigation-item"
                [matMenuTriggerFor]="menu"
              >
                Reports &nbsp;<img src="{{ dropIcon }}" alt="dropdown-icon" />
              </button>
              <mat-menu (change)="handleReportRouting($event)" #menu="matMenu">
                <button
                  mat-menu-item
                  (click)="handleReportRouting('daily-sales-report')"
                >
                  Daily Sales Report
                </button>
                <button
                  mat-menu-item
                  (click)="handleReportRouting('gst-report')"
                >
                  GST Report
                </button>
                <button
                  mat-menu-item
                  (click)="handleReportRouting('drop-customer-report')"
                >
                  Drop Customer Report
                </button>
                <button
                  mat-menu-item
                  (click)="handleReportRouting('month-wise-cat-report')"
                >
                  Month Wise Category
                </button>
                <button
                  mat-menu-item
                  (click)="handleReportRouting('cancelled-orders-report')"
                >
                  Cancelled Orders
                </button>
                <button
                mat-menu-item
                (click)="handleReportRouting('archive')"
              >
                Archive Orders
              </button>
              </mat-menu>
            </div>
            <br />
            <div class="right-section">
              <div style="display: flex">
                <div class="logout" (click)="logoutUser()">
                  <img
                    src="{{
                      assets
                    }}../../../../../assets/images/icons/logout.svg"
                  />
                </div>
                <div class="logout" (click)="goToUrl('profile')">
                  <img
                    src="{{
                      assets
                    }}../../../../../assets/images/icons/profile.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-item-container">
          <div class="logo-section">
            <img class="logo-image"  [src]="loginImage" alt="logo" (click)="goToHome()" />
          </div>
          <!-- <div class="navigation-item" (click)="goToUrl('order-flow/step1')">
            Orders
          </div> -->
          <div style="width: 70%; display: flex; justify-content: center">
            <div class="navigation-item" (click)="goToUrl('dashboard')">
              Dashboard
            </div>
            <div
              class="navigation-item"
              (click)="goToUrl('customer-directory')"
            >
              Customers
            </div>
            <div class="navigation-item" (click)="goToUrl('price')">Prices</div>
            <div class="navigation-item" (click)="goToUrl('coupons-table')">
              Coupons
            </div>
            <!-- <div class="navigation-item" (click)="goToUrl('packages-table')">
              Packages
            </div> -->
            <button
              class="sort-button navigation-item"
              [matMenuTriggerFor]="menu"
            >
              Reports &nbsp;<img src="{{ dropIcon }}" alt="dropdown-icon" />
            </button>
            <mat-menu (change)="handleReportRouting($event)" #menu="matMenu">
              <button
                mat-menu-item
                (click)="handleReportRouting('daily-sales-report')"
              >
                Daily Sales Report
              </button>
              <button mat-menu-item (click)="handleReportRouting('gst-report')">
                GST Report
              </button>
              <button
                mat-menu-item
                (click)="handleReportRouting('drop-customer-report')"
              >
                Drop Customer Report
              </button>
              <button
                mat-menu-item
                (click)="handleReportRouting('month-wise-cat-report')"
              >
                Month Wise Category
              </button>
              <button
                mat-menu-item
                (click)="handleReportRouting('cancelled-orders-report')"
              >
                Cancelled Orders
              </button>
            </mat-menu>
          </div>
          <div class="right-section">
            <div style="display: flex">
              <div class="logout" (click)="logoutUser()">
                <img
                  src="{{
                    assets
                  }}../../../../../assets/images/icons/logout.svg"
                />
              </div>
              <div class="logout" (click)="goToUrl('profile')">
                <img
                  src="{{
                    assets
                  }}../../../../../assets/images/icons/profile.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
