import { HttpClient } from '@angular/common/http';
import {
    EventEmitter,
    Component,
    OnInit,
    Inject,
    Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

interface RunnerStoreInfo {
    store_id: number;
    store_name: string;
    status: number;
}

interface RunnerCheckResponse {
    data: {
        id: number;
        name: string;
        mobile: string;
        stores: RunnerStoreInfo[];
    } | null;
    status: string;
}

@Component({
    selector: 'app-store-runner',
    templateUrl: './store-runner.component.html',
    styleUrls: ['./store-runner.component.scss'],
})
export class StoreRunnerComponent implements OnInit {
    public name: string = '';
    public mobile: string = '';
    public password: string = '';
    public functionality: number;
    public showPasswordField: boolean = false;
    public message: any;
    public messageType: number = -1;
    public status: string = '1';
    private currentStoreId: number;
    public initialStatus: any;

    // New notification properties
    public showCustomNotification: boolean = false;
    public customNotificationMessage: string = '';
    public isCheckingRunner: boolean = false;
    private runnerChecked: boolean = false; // Flag to track if runner has been checked

    @Output() trigger = new EventEmitter<any>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private http: HttpClient,
        private utils: UtilityService,
        private dialogRef: MatDialogRef<StoreRunnerComponent>
    ) {
        this.functionality = data.type;
        const storeData = this.utils.getLocalStorageItem('storeData');
        this.currentStoreId = data.currentStoreId || (storeData ? JSON.parse(storeData).id : null);

        if (data.profile) {
            this.name = data.profile.name;
            this.mobile = data.profile.mobile;
            this.status = data.profile.status?.toString();
        }
    }

    ngOnInit(): void { }

    handleChange(e: any, prop: string): void {
        switch (prop) {
            case 'name':
                this.name = e.target.value;
                break;
            case 'mobile':
                this.mobile = e.target.value;
                this.showCustomNotification = false;
                this.runnerChecked = false; // Reset the check flag
                break;
        }
    }

    showNotification(message: string): void {
        this.showCustomNotification = true;
        this.customNotificationMessage = message;
        setTimeout(() => {
            this.showCustomNotification = false;
            this.customNotificationMessage = '';
        }, 5000);
    }

    async checkExistingRunner(mobile: string): Promise<boolean> {
        if (this.runnerChecked) {
            return true; // If already checked in create runner prevent duplicate check
        }
        this.showCustomNotification = false;
        this.message = '';
        this.messageType = -1;

        if (!mobile || mobile.length !== 10) {
            this.showNotification('Please enter a valid 10-digit mobile number');
            return false;
        }
        this.isCheckingRunner = true;
        try {
            const response = await this.http.get<RunnerCheckResponse>(
                `${environment.SERVER_URL}runners/check/${mobile}?franchise=${environment.FRANCHISE}`,
                {
                    headers: {
                        authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
                    },
                }
            ).toPromise();

            if (!response?.data) {
                return true;
            }

            const currentStoreRunners = response.data.stores.filter(
                store => store.store_id === this.currentStoreId
            );

            const hasActiveEntry = currentStoreRunners.some(runner => runner.status === 1);

            if (hasActiveEntry) {
                this.showNotification('Runner is already active in this store');
                return false;
            }

            return true;

        } catch (error) {
            this.showNotification('Error checking runner status. Please try again.');
            return false;
        } finally {
            this.isCheckingRunner = false;
            this.runnerChecked = true; // set the flag that this check was done.
        }
    }

    closeModal(): void {
        this.dialogRef.close();
    }

    showPassword(): void {
        const passwordElement = document.getElementById('runner-password') as HTMLInputElement;
        if (passwordElement) {
            this.showPasswordField = !this.showPasswordField;
            passwordElement.type = this.showPasswordField ? 'text' : 'password';
        }
    }

    async createRunner(): Promise<void> {
        if (!this.mobile || this.mobile.length !== 10) {
            this.showNotification('Invalid Mobile Number');
            return;
        }

        if (!this.name) {
            this.showNotification('Name cannot be empty');
            return;
        }

        if (!this.password) {
            this.showNotification('Password cannot be empty');
            return;
        }

        const canProceed = await this.checkExistingRunner(this.mobile);
        if (!canProceed) return;

        const runnerObj = {
            name: this.name.trim(),
            mobile: this.mobile,
            password: this.password,
            store_id: this.currentStoreId
        };
        this.http
            .post(
                `${environment.SERVER_URL}runners?franchise=${environment.FRANCHISE}`,
                runnerObj,
                {
                    headers: {
                        authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
                    },
                }
            )
            .subscribe(
                (response: any) => {
                    this.trigger.emit({ success: true });
                    this.showNotification(response.message);
                    setTimeout(() => this.closeModal(), 1000);
                },
                (error) => {
                    this.showNotification(error.error.message);
                }
            );
    }

    updateRunner(): void {
        if (!this.mobile || this.mobile.length !== 10) {
            this.showNotification('Mobile Number cannot be empty or invalid');
            return;
        }

        if (!this.name) {
            this.showNotification('Name cannot be empty');
            return;
        }
        const updateObj = {
            name: this.name.trim(),
            mobile: this.mobile,
            status: this.status,
            id: this.data.profile?.id
        };
        this.http
            .put(
                `${environment.SERVER_URL}runners?franchise=${environment.FRANCHISE}`,
                updateObj,
                {
                    headers: {
                        authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
                    },
                }
            )
            .subscribe(
                (response: any) => {
                    this.trigger.emit({ success: true });
                    this.showNotification(response.message);
                    setTimeout(() => this.closeModal(), 1000);
                },
                (error) => {
                    this.showNotification(error.error.message);
                }
            );
    }
}