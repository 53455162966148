<!-- date-filter.component.html -->
<div class="date-filter-container">
  <!-- Dropdown Toggle -->
  <div 
    class="dropdown-toggle"
    (click)="toggleDropdown()"
  >
    <div class="filter-content">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="filter-icon">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
      </svg>
      <span class="filter-text">
        {{ filter.applyOn && filter.dateOption 
           ? (filter.applyOn + ' - ' + filter.dateOption) 
           : 'Select Date Filter' }}
      </span>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" 
         class="dropdown-icon" [class.rotated]="isOpen">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
    </svg>
  </div>

  <!-- Dropdown Content -->
  <div *ngIf="isOpen" class="dropdown-content">
    <!-- Apply On Section -->
    <div class="filter-section">
      <h4>Apply On</h4>
      <div class="option-group">
        <label 
          *ngFor="let option of applyOnOptions" 
          [class.active]="filter.applyOn === option"
          (click)="selectApplyOn(option)"
        >
          <input 
            type="radio" 
            [value]="option"
            [checked]="filter.applyOn === option"
          >
          {{ option }}
        </label>
      </div>
    </div>

    <!-- Date Options Section -->
    <div *ngIf="filter.applyOn" class="filter-section">
      <h4>Date Option</h4>
      <div class="option-group">
        <label 
          *ngFor="let option of dateOptions"
          [class.active]="filter.dateOption === option"
          (click)="selectDateOption(option)"
        >
          <input 
            type="radio" 
            [value]="option"
            [checked]="filter.dateOption === option"
          >
          {{ option }}
        </label>
      </div>
    </div>

    <!-- Custom Date Range -->
    <div *ngIf="filter.dateOption === 'Custom'" class="custom-date-range">
      <div class="date-input">
        <label for="fromDate">From Date</label>
        <input 
          type="date" 
          id="fromDate"
          [value]="filter.fromDate"
          (change)="onFromDateChange($event.target['value'])"
        >
      </div>
      <div class="date-input">
        <label for="toDate">To Date</label>
        <input 
          type="date" 
          id="toDate"
          [value]="filter.toDate"
          (change)="onToDateChange($event.target['value'])"
        >
      </div>
    </div>

    <!-- Apply Button -->
    <div class="button-group">
      <button 
        (click)="applyFilter()"
        [disabled]="!filter.applyOn || !filter.dateOption || isLoading"
        class="apply-btn"
      >
        {{ isLoading ? 'Loading...' : 'Apply Filter' }}
      </button>
    </div>
  </div>
</div>