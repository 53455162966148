import { Component, EventEmitter, Output, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UtilityService } from 'src/app/service/utility.service';
import { environment} from 'src/environments/environment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-customer-directory-search',
  templateUrl: './customer-directory-search.component.html',
  styleUrls: ['./customer-directory-search.component.scss']
})
export class CustomerDirectorySearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() searchResults = new EventEmitter();
  @Output() totalPagesUpdate = new EventEmitter();
  @Output() searchTermChange = new EventEmitter<string>();

  searchQuery: string = '';
  mobileSearchQuery: string = '';
  mobileSearchError: string = '';
  showSearchTooltip: boolean = false; // Flag to show tooltip
  private currentPage = 1;
  private sort = '';
  private search = '';
  private searchType: 'normal' | 'mobile' = 'normal';
  isFirstTime: boolean = true;

  // Subjects for debouncing search
  private searchSubject = new Subject<string>();
  private mobileSearchSubject = new Subject<string>();

  // Track ongoing HTTP request
  private currentRequest?: Subscription;
  public hand = `${environment.assetUrl}assets/images/icons/hand-gest.svg`;
  // Track all subscriptions
  private subscriptions: Subscription[] = [];
  totalPages: number;
  @ViewChild('searchButton', { static: false }) searchButton: ElementRef;

  constructor(
    private http: HttpClient,
    private utils: UtilityService
  ) { }
  ngAfterViewInit() {
    if (this.searchButton) {
        console.log('Search Button: ', this.searchButton);
      }
  }
  ngOnInit() {
      const hasVisited = localStorage.getItem('hasVisited');
        if (hasVisited) {
            this.isFirstTime = false;
        } else {
            localStorage.setItem('hasVisited', 'true');
            this.isFirstTime = true
        }

    const searchSub = this.searchSubject.pipe(
      debounceTime(300),  // Wait 300ms after last input
      distinctUntilChanged()  // Only emit if value changed
    ).subscribe(searchTerm => {
      this.search = searchTerm;
      this.searchType = 'normal';
      this.currentPage = 1;

      // Check search term length and prevent API call
      if (searchTerm.trim() !== '' && searchTerm.trim().length >= 3) {
        this.searchTermChange.emit(searchTerm); // Emit the search term only if valid
        this.mobileSearchError = '';
      } else {
        this.searchTermChange.emit(''); // Emit empty string if less than 3
        this.mobileSearchError = 'Please enter at least 3 characters to search';
      }

       if (searchTerm.trim() === '') {
        this.mobileSearchError = '';
        this.showSearchTooltip = false;

      } else if (searchTerm.trim().length < 3) {
        // Show error for alphabet searches with less than 3 characters
        this.mobileSearchError = 'Please enter at least 3 characters to search';
        this.showSearchTooltip = false;
      } else {
        // Search term is valid, perform search
        this.mobileSearchError = '';

        if (this.searchButton) {
                this.showSearchTooltip = false; // Show on button click
            } else {
              this.showSearchTooltip = false
            }
      }
    });

    // Setup debounced search for mobile search
    const mobileSub = this.mobileSearchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(searchTerm => {
      this.search = searchTerm;
      this.searchType = 'mobile';
      this.currentPage = 1;

      // Check input and prevent API calls
      if (searchTerm.trim() === '') {
        this.mobileSearchError = '';
        this.showSearchTooltip = false;
        this.searchTermChange.emit('');// Ensure the parent component receives an empty string when the search is cleared
        return;
      }

      // Check if input contains only digits
      const digitsOnly = searchTerm.replace(/\D/g, '');
      const isDigitSearch = /^\d+$/.test(searchTerm.replace(/\D/g, ''));

      if (isDigitSearch) {
        // For digit searches, require 10 digits
        if (digitsOnly.length === 10) {
          this.mobileSearchError = '';
          this.showSearchTooltip = false;
          this.searchTermChange.emit(searchTerm); // Emit the search term
        } else {
          this.mobileSearchError = 'Please enter 10 digit mobile number to search or refresh to see all data';
          this.showSearchTooltip = false;
          this.searchTermChange.emit(''); // Emit empty string so the parent does not execute a search
        }
      } else {
        // For alphabetical searches, require 3 characters
        if (searchTerm.trim().length >= 3) {
          this.mobileSearchError = '';
          this.showSearchTooltip = false;
          this.searchTermChange.emit(searchTerm); // Emit the search term
        } else {
          this.mobileSearchError = 'Please enter at least 3 characters to search or refresh to see all data';
          this.showSearchTooltip = false;
          this.searchTermChange.emit('');// Emit empty string so the parent does not execute a search
        }
      }
    });

    this.subscriptions.push(searchSub, mobileSub);
  }

  ngOnDestroy() {
    // Clean up all subscriptions
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  // Method to handle search bar input
  handleSearch(event: any) {
             const searchTerm = (event.target as HTMLInputElement).value.trim();
                 this.showSearchTooltip = this.mobileSearchQuery.length > 0
        this.searchSubject.next(searchTerm);

        this.isFirstTime = false
  }

  // Method to handle mobile number input
  handleMobileSearch(event: any) {
        const searchTerm = (event.target as HTMLInputElement).value.trim();
                this.showSearchTooltip = this.mobileSearchQuery.length > 0
         this.mobileSearchSubject.next(searchTerm);

        this.isFirstTime = false
  }

  performMobileSearch() {
        this.showSearchTooltip = false
        this.isFirstTime = false
    const searchTerm = this.mobileSearchQuery.trim();
    this.mobileSearchSubject.next(searchTerm);
  }

  setSort(sortValue: string) {
    this.sort = sortValue;
    this.currentPage = 1;
     if (this.searchType === 'mobile') {
            // Check if search is valid for mobile (10 digits if digits only, or at least 3 chars if alphabetical)
            const digitsOnly = this.search.replace(/\D/g, '');
            const isDigitSearch = /^\d+$/.test(this.search.replace(/\D/g, ''));
            if (isDigitSearch && digitsOnly.length === 10) {
                this.searchTermChange.emit(this.search); //Re-trigger the search
            } else if (!isDigitSearch && this.search.trim().length >= 3) {
                this.searchTermChange.emit(this.search); //Re-trigger the search
            } else {
                 this.searchTermChange.emit('');//clear search
            }
        } else {
            //Check if search is valid for alphabet search (at least 3 chars)
            if (this.search.trim().length >= 3) {
                this.searchTermChange.emit(this.search); // Re-trigger the search
            } else{
                 this.searchTermChange.emit('');//clear search
            }
        }
  }

  setPage(page: number) {
    console.log('SetPage called with:', page);
    this.currentPage = page;
    if (this.searchType === 'mobile') {
        // Check if search is valid for mobile (10 digits if digits only, or at least 3 chars if alphabetical)
        const digitsOnly = this.search.replace(/\D/g, '');
        const isDigitSearch = /^\d+$/.test(this.search.replace(/\D/g, ''));
        if (isDigitSearch && digitsOnly.length === 10) {
            this.searchTermChange.emit(this.search); //Re-trigger the search
        } else if (!isDigitSearch && this.search.trim().length >= 3) {
            this.searchTermChange.emit(this.search); //Re-trigger the search
        } else {
            this.searchTermChange.emit(''); //clear search
        }
    } else {
        //Check if search is valid for alphabet search (at least 3 chars)
        if (this.search.trim().length >= 3) {
            this.searchTermChange.emit(this.search); // Re-trigger the search
        } else {
             this.searchTermChange.emit('');//clear search
        }
    }
  }
  clearSearchInput() {
    this.searchQuery = '';
    this.mobileSearchQuery = '';
    this.showSearchTooltip = false; //Hide on clear

  }

    onInputChange() {
        // Show the tooltip only if there's input and it's the first time
        this.showSearchTooltip =  this.mobileSearchQuery.length > 0 //&& this.isFirstTime;
    }
  onInputFocus() {
    this.showSearchTooltip = true
  }
}