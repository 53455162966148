// app.component.ts
import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DRAWER_TYPES } from 'src/assets/constants/app-constant';
import { AppService } from './service/app.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'uclean-suite';
  drawerSubscription!: Subscription;
  isOrderBreakupDrawerVisibile = false;
  franchise = environment.FRANCHISE;
  constructor(
    private appService: AppService,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (this.franchise === 'WHITETIGER') {
        // Apply black theme styles


        this.document.documentElement.style.setProperty("--primary-color", "black");
        this.document.documentElement.style.setProperty("--secondary-color", "black");
        this.document.documentElement.style.setProperty("--primary-gradient-color", "linear-gradient(180deg, #0b583a 0%, #053b26 92.5%)");
        this.document.documentElement.style.setProperty("--black-bg", "#0f5132");
        this.document.documentElement.style.setProperty("--yellow-bg", "#003a26");
        this.document.documentElement.style.setProperty("--notification-background-color-success", "#dcffd7");
        this.document.documentElement.style.setProperty("--notification-background-color-error", "#ffd7d7");
        this.document.documentElement.style.setProperty("--error", "#db0000");
      } else {
        // Apply default theme styles
        this.document.documentElement.style.setProperty("--primary-color", "#0f5132");
        this.document.documentElement.style.setProperty("--secondary-color", "#0f5132");
        this.document.documentElement.style.setProperty("--primary-gradient-color", "linear-gradient(180deg, #0b583a 0%, #053b26 92.5%)");
        this.document.documentElement.style.setProperty("--black-bg", "#323232");
        this.document.documentElement.style.setProperty("--yellow-bg", "#E2E225");
        this.document.documentElement.style.setProperty("--notification-background-color-success", "#dcffd7");
        this.document.documentElement.style.setProperty("--notification-background-color-error", "#ffd7d7");
        this.document.documentElement.style.setProperty("--error", "#db0000");
      }
      
  });

    this.setDrawerSubscription();
  }

  setDrawerSubscription() {
    this.drawerSubscription = this.appService.drawer$.subscribe(
      (drawerInfo: any) => {
        if (drawerInfo) {
          if (drawerInfo?.type == DRAWER_TYPES.orderBreakup) {
            this.isOrderBreakupDrawerVisibile = drawerInfo?.isVisible;
          }
        }
      }
    );
  }

  closeOrderBreakupDrawer() {
    this.appService.drawer$.next({
      type: DRAWER_TYPES.orderBreakup,
      isVisible: false,
    });
  }

  ngOnDestroy() {
    this.drawerSubscription.unsubscribe();
  }
}
