<div class="search-bar-section">
  <div class="search-input-container">
    <input
      class="search-user"
      placeholder="Search Using Mobile Number"
      type="tel"
      maxlength="10"
      [(ngModel)]="mobileSearchQuery"
      (keyup.enter)="performMobileSearch()"
      (focus)="onInputFocus()"
      (input)="onInputChange()"
    />
    <button class="search-icon-button" (click)="performMobileSearch()" #searchButton>
      Search
    </button>
     <div *ngIf="showSearchTooltip" class="search-tooltip animate-up-down">
      <img [src]="hand" alt="Hand Pointer" class="hand-icon" />
     click here to search
    </div>
  </div>
  <div *ngIf="mobileSearchError" class="error-message">
    {{ mobileSearchError }}
  </div>
</div>