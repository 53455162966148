import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';

interface ParenttradeBreakdown {
  id: number;
  name: string;
  quantity: number;
  source: string;
}

interface ApiResponse {
  data: {
    rows: ApiClothDetail[];
    totalQuantity: number;
    clothQuantity: number;
    servicesQuantity: number;
    parenttradesBreakdown: ParenttradeBreakdown[];
    name: string;
    storeName: string;
    bookingCode: string;
    deliveryDate: string | null;
    deliveryTime: string | null;
  };
  status: string;
}

interface ApiClothDetail {
  code: string;
  name: string;
  bookings_quantity: number;
  parenttrade: number;
  parenttradeName: string;
  delivery_date: string | null;
  delivery_time: string | null;
  cloth_name: string | null;
  num_of_items: number | null;
  subtrade_name: string | null;
  cloth_id?: number;
  subtrade_id?: number;
}

interface ProcessedBarcodeItem {
  bookingCode: string;
  customerName: string;
  parenttradeName: string;
  bookings_quantity: number;
  delivery_date: string | null;
  delivery_time: string | null;
  cloth_name: string | null;
  num_of_items: number | null;
  itemNumber: number | null;
  runningTotal: number;
  totalQuantity: number;
  isService: boolean;
  parenttradeId?: number;
  parenttradeQuantity?: number; // Added property
}

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit {
  @ViewChild('barCodeContainer') barCodeContainer!: ElementRef;
  public bookingId: number = 0;
  public barcodeItems: ProcessedBarcodeItem[] = [];
  public totalQuantity: number = 0;
  public clothQuantity: number = 0;
  public servicesQuantity: number = 0;
  public parenttradesBreakdown: ParenttradeBreakdown[] = [];
  public storeName: string = '';
  public bookingCode: string = '';
  public customerName: string = '';
  public deliveryDate: string | null = null;
  public deliveryTime: string | null = null;

  constructor(private route: ActivatedRoute, private apiService: ApiService) {}

  print() {
    const printContent = document.getElementById('barCodeContainer')?.innerHTML;
    const popupWin = window.open('', '_blank', 'width=1000,height=1000');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                  color: black;
                  -webkit-print-color-adjust: exact;
                  print-color-adjust: exact;
                }

                #barCodeContainer {
                  width: 100%;
                }

                .barcode-item {
                margin-left: 0;
                  text-align: center;
                margin-top: 20px;
                margin-bottom: 120px; /* Reduced margin */
                page-break-inside: avoid;
                }

              .store-name {
                font-size: 24px;
                margin-bottom: 5px;
                font-weight: bold;
                margin-top: 10px;
              }

              .totalquantity {
                font-size: 20px;
                margin-bottom: 10px;
                font-weight: bold;
                }

              .bookingType {
                font-size: 22px;
                margin-bottom: 10px;
                font-weight: bold;
                width: 100%;
                  box-sizing: border-box;
                padding: 5px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              .total {
                font-size: 24px;
                  font-weight: bold;
                margin-bottom: 5px;
               }

              .total-quantity {
                font-size: 24px;
                font-weight: bold;
              }

              .name,
              .cloth-name {
                margin-bottom: 5px; /* Reduced margin */
                font-weight: bold;
                font-size: 20px; /* Added font size */
              }

              .delivery-info {
                font-size: 18px; /* Reduced font size */
                margin-top: 5px;
              }

              * {
                font-weight: bold !important;
              }
            }
            </style>
          </head>
          <body onload="window.print();">
            ${printContent}
          </body>
        </html>`);
      popupWin.document.close();
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.bookingId = +params['id'];
      if (this.bookingId) {
        this.fetchData();
      } else {
        console.error('Invalid Booking ID');
      }
    });
  }

  fetchData() {
    this.apiService.generateBarcode(this.bookingId).subscribe({
      next: (response: ApiResponse) => {
        if (response.status === 'success' && response.data) {
          this.totalQuantity = response.data.totalQuantity || 0;
          this.clothQuantity = response.data.clothQuantity || 0;
          this.servicesQuantity = response.data.servicesQuantity || 0;
          this.parenttradesBreakdown = response.data.parenttradesBreakdown || [];
          this.storeName = response.data.storeName || 'N/A';
          this.bookingCode = response.data.bookingCode || 'N/A';
          this.customerName = response.data.name || 'N/A';
          this.deliveryDate = response.data.deliveryDate;
          this.deliveryTime = response.data.deliveryTime;
          this.processBarcodeItems(response.data.rows, response.data.parenttradesBreakdown);
        } else {
          console.error('API request failed or returned unsuccessful status:', response);
        }
      },
      error: (error) => {
        console.error('API request error:', error);
      }
   });
  }

  processBarcodeItems(clothDetails: ApiClothDetail[], parenttrades: ParenttradeBreakdown[]) {
    this.barcodeItems = [];
    let runningTotal = 0;

    clothDetails.forEach(item => {
      const itemCount = (item.num_of_items && item.num_of_items > 0) ? item.num_of_items : 1;
      const bookingQuantity = (item.bookings_quantity && item.bookings_quantity > 0) ? item.bookings_quantity : 0;

      if (bookingQuantity === 0) {
         return;
      }

      // Find the corresponding quantity from parenttradesBreakdown
      const parenttrade = parenttrades.find(pt => pt.id === item.parenttrade);
      const parenttradeQuantity = parenttrade ? parenttrade.quantity : 0;

      for (let q = 0; q < bookingQuantity; q++) {
        for (let i = 0; i < itemCount; i++) {
          runningTotal++;
          const barcodeItem: ProcessedBarcodeItem = {
            bookingCode: this.bookingCode,
            customerName: this.customerName,
            parenttradeName: item.parenttradeName || 'N/A',
            parenttradeId: item.parenttrade,
            bookings_quantity: item.bookings_quantity,
            delivery_date: item.delivery_date ?? this.deliveryDate,
            delivery_time: item.delivery_time ?? this.deliveryTime,
            cloth_name: item.cloth_name || null,
            num_of_items: itemCount,
            itemNumber: itemCount > 1 ? i + 1 : null,
            runningTotal: runningTotal,
            totalQuantity: this.totalQuantity,
            isService: false,
            parenttradeQuantity: parenttradeQuantity,  // Assign the quantity here
          };
          this.barcodeItems.push(barcodeItem);
        }
      }
    });

    const serviceItems = parenttrades.filter(pt => pt.source === 'bookings_services' && pt.quantity > 0);

    serviceItems.forEach(service => {
      for (let i = 0; i < service.quantity; i++) {
        runningTotal++;
        const barcodeItem: ProcessedBarcodeItem = {
          bookingCode: this.bookingCode,
          customerName: this.customerName,
          parenttradeName: service.name,
          parenttradeId: service.id,
          bookings_quantity: 1,
          delivery_date: this.deliveryDate,
          delivery_time: this.deliveryTime,
          cloth_name: null,
          num_of_items: null,
          itemNumber: null,
          runningTotal: runningTotal,
          totalQuantity: this.totalQuantity,
          isService: true,
          parenttradeQuantity: service.quantity // Assign quantity to service
        };
        this.barcodeItems.push(barcodeItem);
      }
    });

    if (runningTotal !== this.totalQuantity) {
        console.warn(`Mismatch: Calculated running total (${runningTotal}) does not match API totalQuantity (${this.totalQuantity}). Check API data and processing logic.`);
    }
  }
}