<div
  class="date-range-container"
  appClickOutside
  (clickOutside)="closeDropdown()"
>
  <div class="dropdown-toggle" (click)="toggleDropdown()">
    <span class="text-content">{{ displayText }}</span>
    <img [src]="dropdownIcon" alt="dropdown">
  </div>
  <div *ngIf="isOpen" class="dropdown-content">
    <label
      *ngFor="let source of sources"
      [class.selected]="selectedSources.includes(source)"
    >
      <input
        type="checkbox"
        [checked]="selectedSources.includes(source)"
        (change)="toggleSource(source)"
      />
      <span class="checkmark"></span>
      {{ source }}
    </label>
  </div>
</div>
