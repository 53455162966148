import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/service/utility.service';
import { AppService } from 'src/app/service/app.service';
import { CustomerDirectorySearchComponent } from '../customer-directory-search/customer-directory-search.component';
import { CustomerDirectoryDateComponent } from '../customer-directory-date/customer-directory-date.component'; // Import the date component

@Component({
  selector: 'app-customer-directory',
  templateUrl: './customer-directory.component.html',
  styleUrls: ['./customer-directory.component.scss'],
})
export class CustomerDirectoryComponent implements OnInit {
  @ViewChild(CustomerDirectorySearchComponent)
  searchComponent: CustomerDirectorySearchComponent;

  @ViewChild(CustomerDirectoryDateComponent)
  dateComponent: CustomerDirectoryDateComponent;

  // UI related properties
  public dropIcon = `${environment.assetUrl}assets/images/icons/dropdown.svg`;
  public pickupIcon = `${environment.assetUrl}assets/images/icons/pickup.svg`;

  // Data related properties
  public users: any = null;
  public totalUsers: number = 0;
  public currentPage: number = 1;
  public totalPages: number = 1;  // Initialize to 1 to prevent issues before data loads
  public pageSize: number = 30;
  public search: string = ''; // Store the search term
  public dateOption: string = ''; //Store dateOption
  public applyOn: string = ''; // Store applyOn
  public fromDate: string = ''; // Store fromDate
  public toDate: string = ''; // Store toDate

  // Sorting related properties
  public sortingItems: any = [];
  public sort: string = '';
  public sortColumn: string = '';
  public sortDirection: string = 'asc';

  constructor(
    private http: HttpClient,
    public apiRep: AppService,
    private router: Router,
    private utils: UtilityService
  ) {
    this.sortingItems = [
      { name: 'Ascending total value', value: 'TVACS' },
      { name: 'Descending total value', value: 'TVDES' },
      { name: 'Ascending total bookings', value: 'TBACS' },
      { name: 'Descending total bookings', value: 'TBDES' },
      { name: 'Ascending last order', value: 'LOACS' },
      { name: 'Descending last order', value: 'LODES' },
    ];
  }

  resetFilters(): void {
    console.log('Resetting filters...');
    // Reset state variables to defaults
  
      console.log('Resetting filters with page reload...');
      window.location.reload();
 
  }


  ngOnInit(): void {
    if (this.apiRep.currentSort) {
      this.sort = this.apiRep.currentSort;
      this.searchComponent?.setSort(this.sort);
    }
    this.getCustomers(); // Initial data load
  }

  // Centralized data fetching
  getCustomers(page: number = this.currentPage) {
    let params: any = {
      franchise: environment.FRANCHISE,
      page: page.toString(),
      sort: this.sort,
      search: this.search,
      dateOption: this.dateOption,
      applyOn: this.applyOn
    };

    if (this.dateOption === 'Custom') {
      params.fromDate = this.fromDate;
      params.toDate = this.toDate;
    }

    this.http
      .get(`${environment.SERVER_URL}webuser/getCustomerUnified`, {
        params: params,
        headers: {
          Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe({
        next: (data: any) => {
           console.log('API Data Received:', data);  //Debugging Line
          this.users = data.data;
          //Check if pagination exists
          if(data.pagination) {
          this.totalPages = data.pagination.totalPages;
          this.currentPage = page; // Update current page after successful fetch
          this.totalUsers = data.pagination.total; // Also update totalUsers
          } else {
            console.warn("Pagination data is missing from the API response.");
            this.totalPages = 1;  // Provide a default value
            this.totalUsers = this.users?.length || 0;

          }
          console.log('getCustomers() - API Response:', data);
          console.log('getCustomers() - this.totalPages:', this.totalPages);
          console.log('getCustomers() - this.currentPage:', this.currentPage);
        },
        error: (error) => {
          console.error('Error fetching customers:', error);
          this.users = [];
          this.totalPages = 1;
          this.totalUsers = 0;
        },
      });
  }

  // Event handlers from child components
  handleSearchResults(results: any) {
    console.log('handleSearchResults received:', results);
    this.users = results.data;
    //Check if pagination exists
    if(results.pagination){
        this.totalPages = results.pagination.totalPages;
    this.currentPage = results.pagination.page;
    this.totalUsers = results.pagination.total;
    } else {
         console.warn("Pagination data is missing from the search results.");
         this.totalPages = 1;  // Provide a default value
         this.totalUsers = this.users?.length || 0;

    }

    console.log('handleSearchResults() - this.totalPages:', this.totalPages);
    console.log('handleSearchResults() - this.currentPage:', this.currentPage);
  }

  handleSearchTermChange(searchTerm: string) {
    this.search = searchTerm;
    this.currentPage = 1; // Reset to the first page when searching
    this.getCustomers(1); // Load the first page of search results
  }

  handleTotalPagesUpdate(totalPages: number) {
    console.log('handleTotalPagesUpdate received:', totalPages);
    this.totalPages = totalPages;
  }

  handleDateFilterChange(filter: any) {
    this.dateOption = filter.dateOption;
    this.applyOn = filter.applyOn;
    this.fromDate = filter.fromDate;
    this.toDate = filter.toDate;
    this.currentPage = 1; // Reset to the first page
    this.getCustomers(1); // Load the first page with the new filter
  }

  clearDateFilter() {
    this.dateOption = '';
    this.applyOn = '';
    this.fromDate = '';
    this.toDate = '';
    this.currentPage = 1;
    this.getCustomers(1);
  }

  // Pagination
  updatePageNumber(ev: any) {
    console.log('updatePageNumber received:', ev);
    this.currentPage = ev.page;
    this.getCustomers(this.currentPage);
  }

  // Sorting
  sortData(val: any) {
    this.sort = val;
    this.currentPage = 1;
    this.getCustomers(1);
  }

  sortAscending(column: string) {
    // Existing Sorting Logic
  }

  // Utility methods
  getSortIcon(column: string): string {
    if (this.sortColumn !== column) return '↕';
    return this.sortDirection === 'asc' ? '↑' : '↓';
  }

  getFormattedDate(date: string) {
    if (!date) return '';
    return new Date(date).toDateString();
  }

  formatNumber(numb: number) {
    return Math.round(numb);
  }

  // Navigation
  public gotoUrl(url: string) {
    this.router.navigateByUrl(url);
  }

  navigateToUserDetails(userId: number) {
    this.router.navigate(['dashboard/user', userId]);
  }
}