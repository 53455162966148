import { HttpClient } from '@angular/common/http';
import {
  EventEmitter,
  Component,
  OnInit,
  Inject,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

interface SelectedItem {
  name: string;
  mobile: number | null;
  id: number | null;
}

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Output() trigger = new EventEmitter<any>();
  private getURL: string = '';
  private postURL: string = '';
  private bookingId: number | number[] = 0;
  public fetchedItems: any;
  public selectedItem: SelectedItem;
  public message: any = '';
  public messageType: number = -1;
  public title: string = '';
  public notifyCustomer: boolean = false;
  public notifyPickupBoy: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private utils: UtilityService,
    private dialogRef: MatDialogRef<ModalComponent>
  ) {
    this.getURL = data.fetchURL;
    this.postURL = data.postURL;
    this.bookingId = data.bookingId;
    this.title = data.title;
    this.fetchData();
    this.selectedItem = {
      name: '',
      mobile: null,
      id: null,
    };
  }

  closeModal = () => {
    this.dialogRef.close();
  }

  ngOnInit = () => {
    this.fetchData();
  }

  typeOfMessage = (type: string | string[]) => {
    return !!Array.isArray(type);
  }

  renderNameImg = (name: string) => {
    if (!name || name === '') {
      return;
    }
    return name[0].toUpperCase();
  }

  onClose(data: string): void {
    this.trigger.emit('Data to emit');
    this.dialogRef.close();
  }

  emitData = () => {};

  handlePost = () => {
    this.message = '';
    this.messageType = -1;
    this.http
      .post(
        `${environment.SERVER_URL}${this.postURL}`,
        {
          runner: this.selectedItem.id,
          booking: this.bookingId,
          notifyCustomer: this.notifyCustomer,
          notifyPickupBoy: this.notifyPickupBoy,
        },
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe(
        (data: any) => {
          this.trigger.emit({ success: true });
          console.log(data);
          this.message = data.message;
          this.messageType = 0;
          setTimeout(() => {
            this.closeModal();
          }, 500);
        },
        (err) => {
          this.message = err.error.message;
          this.messageType = 1;
        }
      );
  }

  handleSendNotifications = () => {};

  handleSelect = (item: SelectedItem) => {
    if (this.selectedItem.id === item.id) {
      this.selectedItem = {
        name: '',
        mobile: null,
        id: null,
      };
    } else {
      this.selectedItem = { ...item }; // Create a new object
    }
  }

  fetchData = () => {
    this.http
      .get(`${environment.SERVER_URL}${this.getURL}`, {
        params: { franchise: environment.FRANCHISE },
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        console.log(data);
        this.fetchedItems = data.data;
      });
  }

  getButtonClass(item: SelectedItem): string {
    return this.selectedItem.id === item.id ? 'btn primary' : 'btn secondary';
  }
}
