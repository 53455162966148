<section class="customer-directory-section" style="margin-top: 100px">
  <div class="container" style="margin-left: 30px;">
  <app-gst-date-component (dateRangeSelected)="onDateRangeSelected($event)"></app-gst-date-component>
  <button class="btn primary export"(click)="exportReport()">
    <img [src]="export" class="export-icon"  alt="export-icon" /><span></span>&nbsp;Export Report
  </button>
  </div>
  
  
  <div class="container">
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="table-section mt-20">
          <div *ngIf="message">
            <app-notifications [message]="message" [type]="messageType"></app-notifications>
          </div>
          
          <br />
           <div class="dsr-body">
          <div class="group-section">
            <button
              mat-ripple
              class="group-item"
              (click)="showReport('DSR')"
              [class.active]="selectedReport === 'DSR'"
            >
              DSR REPORT
              <div class="active-indicator"></div>
            </button>
            <!-- <button
              mat-ripple
              class="group-item"
              (click)="showReport('Collection')"
              [class.active]="selectedReport === 'Collection'"
            >
              Collection Report
              <div class="active-indicator"></div>
            </button>
            <button
              mat-ripple
              class="group-item"
              (click)="showReport('Quantity')"
              [class.active]="selectedReport === 'Quantity'"
            >
              Quantity Report
              <div class="active-indicator"></div>
            </button> -->
          </div>
          
          <div *ngIf="selectedReport === 'DSR' && data">
            <h2>DSR REPORT</h2>
            <div class="table-section">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>D.C. Sale</th>
                    <th>PL/KG Sale</th>
                    <th>Laundry (W & F) Sale</th>
                    <th>Laundry (W & I) Sale</th>
                    <th>S.P. Sale</th>
                    <th>S.I. Sale</th>
                    <th>Fabric Softener</th>
                    <th>Antiseptic Wash</th>
                    <th>Hanger Packing</th>
                    <th>Discount</th>
                    <th>Net Amount</th>
                    <th>GST (18%)</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of data">
                    <td>{{ item.odate | date:'dd/MM/yyyy' }}</td>
                    <td>{{ item.dc_sale | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.premium_laundry | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.laundry_wash_fold | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.laundry_wash_iron | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.steam_press | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.standard_iron | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.addon_1 | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.addon_2 | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.addon_3 | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.discount | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ calculateNetAmount(item) | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.tax | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.total_sale | currency:'INR':'symbol':'1.2-2' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div *ngIf="selectedReport === 'Collection' && data">
            <h2>COLLECTION REPORT</h2>
            <div class="table-section">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Cash</th>
                    <th>Credit/Debit Card</th>
                    <th>UPI/Wallet</th>
                    <th>Bank Transfer</th>
                    <th>Total Payment Received</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of data.dailySummary">
                    <td>{{ item.date | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ item.paymentModes['4']?.amount | currency: 'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.paymentModes['2']?.amount | currency: 'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.paymentModes['1']?.amount | currency: 'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.paymentModes['3']?.amount | currency: 'INR':'symbol':'1.2-2' }}</td>
                    <td>
                      {{ 
                        (item.paymentModes['4']?.amount || 0) + 
                        (item.paymentModes['2']?.amount || 0) + 
                        (item.paymentModes['1']?.amount || 0) + 
                        (item.paymentModes['3']?.amount || 0) | currency: 'INR':'symbol':'1.2-2' 
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              
            </div>
          </div>
          

          <div *ngIf="selectedReport === 'Quantity' && data">
            <h2>QUANTITY REPORT</h2>
            <div class="table-section">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>D.C. Qty</th>
                    <th>PI/Kg Qty Pcs.</th>
                    <th>PI/Kg Qty KG</th>
                    <th>Laundry (W & I) Qty Pcs.</th>
                    <th>Laundry (W & F) Qty KG</th>
                    <th>S.P. Qty</th>
                    <th>S.I. Qty</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of data; let i = index">
                    <td>{{ item.date | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ item.dc_quantity }}</td>
                    <td>{{ item.pi_kg_quantity_pcs }}</td>
                    <td>{{ item.pi_kg_quantity_kg }}</td>
                    <td>{{ item.laundry_quantity_pcs }}</td>
                    <td>{{ item.laundry_quantity_kg }}</td>
                    <td>{{ item.sp_quantity }}</td>
                    <td>{{ item.si_quantity }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          
         </div>
        </div>
      </div>
    </div>
  </div>
</section>