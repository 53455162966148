<div class="search-container">
  <input
  class="search-input"
   
      (keyup)="handleSearch($event)"
      (input)="handleInputChange($event)"
      placeholder="Enter Mobile No., Name, Booking Code..."
      [(ngModel)]="searchQuery"
      (ngModelChange)="handleInputChange($event)"
  />
</div>