import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';
import { InvoiceService } from '../service/invoice.service';
import { ViewInvoiceComponent } from 'src/app/view-invoice/view-invoice.component';
import { PaymentModalComponent } from '../feature/dashboard/payment-modal/payment-modal.component';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
})
export class ArchiveComponent implements OnInit {
  public sortQuery: string = '';
  sortColumn: string = '';
  sortDirection: string = 'asc';
  public sortLabel: string = 'Sort by';
  public selectedFilter: string = '';
  public currentPage: number = 1;
  public itemsPerPage: number = 30;  // Number of items per page
  public today: string = '';
  public allOrders: any[] = [];  // Store all orders from the backend
  public orders: any = null;   // Store the orders for the current page
  public totalPages: number = 0;
  public searchQuery: string = '';
  public fromDate: string = '';
  public toDate: string = '';
  public dateS: string = 'Select';
  public dateSortingOptions: string[] = ['Pickup date', 'Delivery Date'];

  public sortingItems: any[] = [
    { name: 'Pickup date DESC', value: 'PUDSC' },
    { name: 'Pickup date ASC', value: 'PUASC' },
    { name: 'Booking code DESC', value: 'OCDSC' },
    { name: 'Booking code ACS', value: 'OCACS' },
    { name: 'Invoice code ACS', value: 'INACS' },
    { name: 'Invoice code DESC', value: 'INDES' },
    { name: 'Drop date ACS', value: 'DDASC' },
    { name: 'Drop date DESC', value: 'DDDSC' },
  ];

  public dropIcon = `${environment.assetUrl}assets/images/icons/dropdown_green.svg`;
  selectedSources: string[];
  selectedOrder: any;
  showModal: boolean;
  finalAmount: number;
  public error=`${environment.assetUrl}assets/orderthrough/error.svg`;
  constructor(
    private invoiceService: InvoiceService,
    private http: HttpClient,
    private utils: UtilityService,
    private router: Router,
    private dialogRef: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getData();
    this.setToday();
  }

  showPaymentDetails(order: any): void {
    this.selectedOrder = order;
    this.showModal = true;
  }

  closePaymentModal(): void {
    this.showModal = false;
    this.selectedOrder = null;
  }

  getPaymentDetailsArray(paymentDetails: string): any[] {
    try {
      return JSON.parse(paymentDetails);
    } catch (error) {
      console.error('Error parsing payment details:', error);
      return [];
    }
  }

  complete(orderId: number) {
    console.log("Complete method called for orderId:", orderId);
    const dialogRef = this.openPaymentModal(orderId, this.finalAmount);
    dialogRef.afterClosed().subscribe(result => {
      console.log("Payment modal closed. Result:", result);
      if (result && result.statusResponse && result.statusResponse.status === 'success') {
        console.log("Order status update response:", result.statusResponse);
        this.updateOrderInList(orderId);
      } else {
        console.warn("Status not updated to 7, check statusResponse.");
      }
    });
  }
  openPaymentModal(bookingId: number, finalAmount: number) {
    // Find the specific order from orders array using bookingId
    const selectedOrder = this.orders.find(order => order.id === bookingId);
    
    console.log("Opening payment modal for bookingId:", bookingId);
    console.log("Selected order:", selectedOrder);
    
    // Use the final_amount from the selected order
    const orderFinalAmount = selectedOrder?.final_amount;
    
    return this.dialogRef.open(PaymentModalComponent, {
      width: '500px',
      data: {
        bookingId: bookingId,
        finalAmount: orderFinalAmount,
        isFromArchive: true // Add this flag!
      }
    });
  }

  updateOrderInList(orderId: number) {
    this.orders = this.orders.map(order => {
      if (order.id === orderId) {
        return { ...order, status: 7, payment_details: 'Payment Done' }; // Update the status or payment details
      }
      return order;
    });
  }

  renderPaymentMode(mode: number): string {
    // Map payment modes to names
    const paymentModes = {
      1: 'Credit Card',
      2: 'Debit Card',
      3: 'Paypal',
      4: 'Cash',
      // Add more as needed
    };
    return paymentModes[mode] || 'Unknown';
  }

  getSortIcon(column: string): string {
    if (this.sortColumn !== column) return '↕';
    return this.sortDirection === 'asc' ? '↑' : '↓';
  }

  setToday() {
    const today = new Date();
    this.today = today.toISOString().split('T')[0];
  }
  sortDate(event: Event) {
    this.dateS = (event.target as HTMLSelectElement).value;
  }
  handleSort(column: string): void {
    // If clicking the same column, toggle direction
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // If clicking a new column, set it as the sort column and default to asc
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }

    // Map column and direction to your existing sort query format
    const sortMapping: { [key: string]: { asc: string; desc: string } } = {
      'order.booking_code': { asc: 'OCACS', desc: 'OCDSC' },
      'order.pickupDate': { asc: 'PUASC', desc: 'PUDSC' },
      'order.delivered_at': { asc: 'DDASC', desc: 'DDDSC' },
      // Add more mappings as needed
    };

    if (sortMapping[column]) {
      this.sortQuery = sortMapping[column][this.sortDirection];
      this.getData();
    }
  }

  handleDateChange(dateEvent: any) {
    console.log("atleast it is coming here");
    this.dateS = dateEvent.dateType === 'Pickup Date' ? 'Pickup date' : 'Delivery Date';
    this.fromDate = dateEvent.from;
    this.toDate = dateEvent.to;
    this.getData(); // Call the new filtered data function
  }

  setDatesAndSort() {
    if (this.fromDate !== '' && this.toDate !== '' && this.dateS !== 'Select') {
      this.getData();
    }
    if (this.fromDate && this.toDate && this.fromDate > this.toDate) {
      this.toDate = this.fromDate;
    }
  }

  sort(q: any) {
    this.orders = null;
    this.sortQuery = q.value;
    this.sortLabel = q.name;
    this.getData();
  }

  handleSearch(e: any) {
    this.searchQuery = e.target.value;
    this.getData();
  }

  updatePageNumber(event: any) {
    this.currentPage = event.page;
    this.updateDisplayedOrders();
  }

  getData() {
    // Only proceed with API call if search query is at least 3 characters long
    if (this.searchQuery && this.searchQuery.trim().length < 3) {
      return; // Exit the method if search query is less than 3 characters
    }
  
    const headers = new HttpHeaders().set(
      'authorization',
      `Bearer ${this.utils.getLocalStorageItem('token')}`
    );
  
    let params = new HttpParams()
      .set('franchise', environment.FRANCHISE);
  
    // Add search query if it meets the minimum length
    if (this.searchQuery && this.searchQuery.trim().length >= 3) {
      params = params.set('searchQuery', this.searchQuery.trim());
    }
  
    // Add sorting
    if (this.sortQuery) {
      params = params.set('sortQuery', this.sortQuery);
    }
  
    // Add date range filtering
    if (this.fromDate && this.toDate) {
      params = params.set('startDate', this.fromDate);
      params = params.set('endDate', this.toDate);
      params = params.set('dateType', this.dateS === 'Pickup date' ? 'pickup' : 'delivery');
      params = params.set('dateRange', 'custom')
    }
  
    // Add selected order sources
    if (this.selectedSources && this.selectedSources.length > 0) {
      params = params.set('orderSources', this.selectedSources.join(','));
    }
  
    this.http
      .get(`${environment.SERVER_URL}bookings/getDeliveredOrders`, { headers, params })
      .subscribe((data: any) => {
        this.allOrders = data.data;  // Store all fetched orders
        this.totalPages = Math.ceil(this.allOrders.length / this.itemsPerPage);
        this.updateDisplayedOrders();
      });
  }

  // Function to update the displayed orders based on the current page
  updateDisplayedOrders() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.orders = this.allOrders.slice(startIndex, endIndex);
  }

  renderIcons = (num: number) => {
    switch (num) {
      case 1:
        return `${environment.assetUrl}assets/images/icons/order-through/walk.svg`;
      case 2:
        return `${environment.assetUrl}assets/images/icons/order-through/phone.svg`;
      case 3:
        return `${environment.assetUrl}assets/images/icons/order-through/store.svg`;
      case 4:
        return `${environment.assetUrl}assets/images/icons/order-through/mobile.svg`;
      case 5:
        return `${environment.assetUrl}assets/images/icons/order-through/laptop.svg`;
      case 6:
        return `${environment.assetUrl}assets/images/icons/order-through/whatsapp.svg`;
      default:
        return '';
    }
  }

  onSourcesChanged(selectedSources: string[]): void {
    this.selectedSources = selectedSources;
    this.getData();
  }

  viewInvoice = (id: number) => {
    this.invoiceService.previewInvoice(id).subscribe((data) => {
      const reader = new FileReader();
      reader.readAsText(data);

      reader.onloadend = () => {
        this.dialogRef.open(ViewInvoiceComponent, {
          data: reader.result!.toString(),
          width: '230mm',
          height: '230mm',
          panelClass: 'a4-dialog'
        });
      };
    });
  }

  renderStatus(s: number): string {
    switch (true) {
      case s === 0:
        return 'Cancel';
      case s === 7:
        return 'Delivered';
      default:
        return 'Unknown';
    }
  }


  displayTime = (datetime: string) => {
    if (datetime) {
      return moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY, hh:mm A');
    } else {
      return '';
    }
  }

  resetFilters = () => {
    this.orders = null;
    this.sortQuery = '';
    this.currentPage = 1;
    this.selectedFilter = '';
    (document.querySelector('.search-user') as HTMLInputElement).value = '';
    this.toDate = '';
    this.fromDate = '';
    this.dateS = 'Select';
    this.getData();
  }

  gotoUrl = (url: string) => {
    this.router.navigateByUrl(url);
  }
}