<section class="order-flow-section">
  <div class="fixed-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="!typeOfMessage(message)">
            <app-notifications
              [message]="message"
              [type]="messageType"
            ></app-notifications>
          </div>

          <div class="dashboard-heading container">
            <app-search
              [orderType]="type"
              (searchPerformed)="onSearchPerformed($event)"
            ></app-search>
            <app-order-source
              (sourcesChanged)="onSourcesChanged($event)"
            ></app-order-source>
            <app-date-range
              *ngIf="type === 'NEW'"
              [type]="type"
              (dateRangeChange)="onDateRangeChange($event)"
            >
            </app-date-range>

            <app-date-range
              *ngIf="type === 'INPRO'"
              [type]="type"
              (dateRangeChange)="onDateRangeChange($event)"
            >
            </app-date-range>
            <button class="btn primary resetbtn" (click)="resetFilters()" style="margin-left: 10px; ">Reset</button>
            <button
              class="btn primary addorderbtn"
              style="margin-left: auto"
              (click)="gotoUrl('order-flow/step1')"
            >
              + Add Order
            </button>
          </div>
          <div *ngIf="activeFiltersText" class="active-filters-display">
            {{ activeFiltersText }}
        </div>
          <div class="group-section">
            <button
              mat-ripple
              class="group-item"
              (click)="isNewOrderListed = true; type = 'NEW'; getData()"
              [ngClass]="{ active: isNewOrderListed }"
            >
              New Orders
              <div class="active-indicator"></div>
            </button>
            <button
              mat-ripple
              class="group-item"
              (click)="
                isNewOrderListed = false; type = 'INPRO'; getData()
              "
              [ngClass]="{ active: !isNewOrderListed }"
            >
              In Process
              <div class="active-indicator"></div>
            </button>
          </div>
          <div class="line"></div>
          <br />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="table-section">
          <table *ngIf="orders !== null" [ngClass]="type">
            <thead>
              <tr>
                <th class="order-align">
                  <input
                    type="checkbox"
                    class="order-checkbox"
                    (click)="selectAll()"
                  />
                  <span>Order</span>
                  <span
                    class="sort-icon"
                    (click)="sortAscending('booking_code')"
                    >{{ getSortIcon("booking_code") }}</span
                  >
                </th>
                <th *ngIf="type === 'NEW'" class="status-width">
                  <span>Pickup D </span>
                  <span
                    class="sort-icon"
                    (click)="sortAscending('pickup_date')"
                    >{{ getSortIcon("pickup_date") }}</span
                  >
                </th>
                <th *ngIf="type === 'NEW'" class="status-width">
                  <span>Pickup T </span>
                  <span
                    class="sort-icon"
                    (click)="sortAscending('pickup_time')"
                    >{{ getSortIcon("pickup_time") }}</span
                  >
                </th>
                <th class="status-width" *ngIf="type === 'INPRO'">
                  <span>Pickup</span>
                  <span
                    class="sort-icon"
                    (click)="sortAscending('pickup_date')"
                    >{{ getSortIcon("pickup_date") }}
                    </span
                  >
                </th>
                <th class="status-width" *ngIf="type === 'INPRO'">
                  <span>Delivery</span>
                  <span
                    class="sort-icon"
                    (click)="sortAscending('delivery_date')"
                    >{{ getSortIcon("delivery_date") }}</span
                  >
                </th>
                <th *ngIf="type === 'NEW'">
                  <span>Customer</span>
                </th>
                <th class="custom-width" *ngIf="type === 'NEW'">
                  <span>Address</span>
                </th>
                <th class="custom-width" *ngIf="type === 'NEW'">
                  <span>Instructions</span>
                </th>
                <th *ngIf="type === 'INPRO'">
                  <span>Invoice</span>
                </th>
                <th *ngIf="type === 'INPRO'">
                  <span>Order ready</span>
                </th>
                <th *ngIf="type === 'INPRO'">
                  <span>Assign delivery</span>
                </th>
                <th *ngIf="type === 'INPRO'">
                  <span>Delivery done</span>
                </th>
                <th class="action-width" *ngIf="type === 'NEW'">
                  <span>Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let orderObj of orders; let i = index"
                [ngClass]="{
                  active: multipleBookings.includes(orderObj),
                  'order-date-passed': convertToDate(orderObj?.pickup_date)
                }"
              >
                <td>
                  <input
                    type="checkbox"
                    class="order-checkbox"
                    [checked]="isSelectedInMultiple(orderObj.id)"
                    (change)="handleAddOrRemoveFromBookings($event, orderObj)"
                  />
                  <span>
                    <img
                      [src]="renderIcons(orderObj.orderthrough_id)"
                      alt="Order icon"
                    />
                    <span class="booking-code">{{
                      orderObj?.booking_code
                    }}</span>
                  </span>
                  <ng-container *ngIf="type === 'NEW'">
                    <div class="info">
                      <div class="date-time-container">
                        <div>{{ displayTime(orderObj.created_at).date }}</div>
                        <div>{{ displayTime(orderObj.created_at).time }}</div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="type === 'INPRO'">
                    <div class="info">
                      <div style="font-weight: 600">
                        {{ orderObj?.name }}
                      </div>
                      <div>
                        {{ orderObj?.mobile }}
                      </div>
                      <div>
                        <app-address
                          [address]="formatAddress(orderObj?.address)"
                        ></app-address> 
                      </div>
                    </div>
                  </ng-container>
                </td>
                <td *ngIf="type === 'NEW'">
                  <div *ngIf="orderObj.orderthrough_id === 1 ">
                    Walk-in Store
                  </div>
                  <div class="date-time-container"  *ngIf="orderObj.orderthrough_id != 1 ">
                    <div>{{ displayTime(orderObj.pickup_date).date }}</div>
                  </div>
                </td>
                <td *ngIf="type === 'NEW'">
                   {{ orderObj?.pickup_time || "" }}
                </td>
                <!-- In your template -->
                <td *ngIf="type === 'INPRO'">
                  <div class="view-challan" *ngIf="orderObj.challan_number != null" >
                    {{ orderObj.challan_number }}
                </div>
                  <div *ngIf="orderObj.orderthrough_id === 1 ">
                    Walk-in Store
                  </div>
                  <div class="date-time-container"  *ngIf="orderObj.orderthrough_id != 1 ">
                    <div>{{ displayTime(orderObj.pickup_date).date }}</div>
                    <div>{{ orderObj.pickup_time}}</div>
                  </div>
                </td>
                <td *ngIf="type === 'INPRO'">
                  <div class="view-inprocess" *ngIf="orderObj.delivery_date == null ">
                   In Process
                  </div>
                  <div class="date-time-container" *ngIf="orderObj.delivery_date != null ">
                    <div>{{displayTime(orderObj.delivery_date).date }}</div>
                    <div>{{ orderObj.delivery_time }}</div>
                  </div>
                </td>
                <td *ngIf="type === 'NEW'">
                  <div class="name">{{ orderObj?.name }}</div>
                  <div class="darkGreen">{{ orderObj?.mobile }}</div>
                </td>

                <!-- Address Column -->
                <td class="custom-width" *ngIf="type === 'NEW'">
                 

                  <div class="address-container" [class.expanded]="isAddressExpanded">
                    <div class="address-content">
                      <ng-container *ngIf="!isAddressExpanded; else fullAddress">
                        {{ getShortAddress(orderObj?.address) }}
                      </ng-container>
                      <ng-template #fullAddress>
                        {{ orderObj?.address }}
                      </ng-template>
                    </div>
                    <button class="toggle-btn" (click)="toggleAddress($event)">
                      {{ isAddressExpanded ? 'Show Less' : 'Show More' }}
                    </button>
                  </div>

                  
                </td>

                <!-- Instructions Column -->
                <td
                  class="custom-width address-wrapper"
                  style="color: #629c42"
                  *ngIf="type === 'NEW'"
                >
                  <div
                    class="instructions-container"
                    [class.expanded]="isInstructionsExpanded[i]"
                  >
                    <app-address
                      *ngIf="orderObj?.suggestions?.length !== 0"
                      [address]="orderObj?.suggestions"
                    ></app-address>
                    <span *ngIf="orderObj?.suggestions?.length === 0">NA</span>
                  </div>
                </td>
                <!-- Add more cells for INPRO type here -->
                <td *ngIf="type === 'NEW'">
                  <div class="action-container">
                    <button
                      *ngIf="orderObj?.status === 1"
                      class="btn primary inprobtn"
                      (click)="assignPickup(orderObj.id)"
                    >
                      Assign Pickup
                    </button>
                    <div class="cancel-icon">
                      <img
                        [src]="cross"
                        alt="Cancel"
                        (click)="handleCancel(orderObj.id)"
                      />
                    </div>
                  </div>
                </td>
                <td
                  class="order-progress"
                  *ngIf="type === 'INPRO'"
                  colspan="4"
                >
                  <div
                    class="lines"
                    style="display: flex; justify-content: space-around"
                  >
                    <div
                      class="horizontal-line"
                      [ngClass]="{
                        generate: orderObj.status <= 2,
                        invoice: orderObj.status > 2
                      }"
                    ></div>
                    <div
                      class="horizontal-line"
                      [ngClass]="{
                        'mark-ready':
                          orderObj.status === 3 || orderObj.status === 4,
                        'order-ready': orderObj.status > 3,
                        'before-order-ready': orderObj.status <= 2
                      }"
                    ></div>
                    <div
                      class="horizontal-line"
                      style="left: 0% !important"
                      [ngClass]="{
                        'assign-delivery': orderObj.status >= 7,
                        'generate-order-ready': orderObj.status > 2,
                        'before-order-ready': orderObj.status <= 2
                      }"
                    ></div>
                    <div
                      class="horizontal-line"
                      style="
                        background-color: transparent !important;
                        width: 0px !important;
                      "
                    ></div>
                  </div>

                  <div class="progress-container">
                    <div
                      class="progress-step"
                      [ngClass]="getStepClass(orderObj.status, 2)"
                    >
                      <ng-container
                        *ngIf="orderObj.status <= 2; else invoiceGenerated"
                      >
                        <button
                          class="btn primary inprobtn"
                          (click)="gotoUrl('invoice-flow/' + orderObj.id)"
                        >
                          Generate
                        </button>
                      </ng-container>
                      <ng-template #invoiceGenerated>
                        <div
                          class="completed-step btn primary "
                          style="
                            background-color: rgba(38, 104, 77, 0.1) !important;
                            box-shadow: none !important;
                            display: flex !important;
                          "
                        >
                          <div style="display: flex">
                            <img
                              (click)="printBarCode(orderObj.id)"
                              [src]="qrIcon"
                              style="
                                height: 30px;
                                width: 30px;
                                padding-right: 2px !important;
                                padding-top: 4px !important;
                              "
                              alt="QR Code"
                            />
                            <div
                              class="view-invoice"
                              (click)="viewInvoice(orderObj?.id)"
                            >
                            {{ orderObj?.invoice_id }}
                            </div>
                         
    
                          </div>
                       
                        </div>
                           
                        <div style="font-size: 17px" class="timestamp">
                          Final Amount: {{ orderObj?.final_amount }}
                        </div>
                       
                        <div class="timestamp">
                          <div class="date-time-container">
                            <div>{{
                              displayTime(orderObj.in_process_at).date
                            }}</div>
                            <div>{{
                              displayTime(orderObj.in_process_at).time
                            }}</div>
                          </div>
                        </div>
                      </ng-template>
                    </div>

                    <div
                      class="progress-step"
                      [ngClass]="getStepClass(orderObj.status, 3)"
                    >
                      <button
                        class="btn inprobtn"
                        [ngClass]="{
                          primary: orderObj.status === 3,
                          disabled: orderObj.status < 3,
                          activ: orderObj.status > 3
                        }"
                        (click)="
                          orderObj.status === 3 && updateOrder(orderObj.id)
                        "
                      >
                        {{ orderObj.status > 3 ? "Order Ready" : "Mark Ready" }}
                      </button>
                      <div *ngIf="orderObj.status > 3" class="timestamp">
                        <div class="date-time-container">
                          <div>{{
                            displayTime(orderObj.order_ready_at).date
                          }}</div>
                          <div>{{
                            displayTime(orderObj.order_ready_at).time
                          }}</div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="progress-step"
                      [ngClass]="getStepClass(orderObj.status, 4)"
                    >
                      <button
                        class="btn inprobtn"
                        [ngClass]="{
                          primary: orderObj.status === 4,
                          disabled: orderObj.status < 4,
                          activ: orderObj.status > 4
                        }"
                        (click)="
                          orderObj.status === 4 && assignDrop(orderObj.id)
                        "
                      >
                        {{
                          orderObj.status > 4 ? "Assigned" : "Assign delivery"
                        }}
                      </button>
                      <div *ngIf="orderObj.status > 4" class="timestamp">
                        <div class="date-time-container">
                          <div>{{
                            displayTime(orderObj.drop_rider_assign_at).date
                          }}</div>
                          <div>{{
                            displayTime(orderObj.drop_rider_assign_at).time
                          }}</div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="progress-step"
                      [ngClass]="getStepClass(orderObj.status, 5)"
                    >
                      <button
                        class="btn inprobtn"
                        [ngClass]="{
                          primary:
                            orderObj.status === 5 || orderObj.status === 6,
                          disabled: orderObj.status < 5,
                          activ: orderObj.status >= 7
                        }"
                        (click)="
                          (orderObj.status === 5 || orderObj.status === 6) &&
                            complete(orderObj.id)
                        "
                      >
                        {{ orderObj.status >= 7 ? "Delivered" : "Mark Done" }}
                      </button>
                      <div *ngIf="orderObj.status >= 7" class="timestamp">
                        <div class="date-time-container">
                          <div>{{ displayTime(orderObj.delivery_date).date }}</div>
                          <div>{{ displayTime(orderObj.delivery_date).time }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <br />
    <div>
      <app-page-index
        [currentPage]="currentPage"
        [totalPages]="totalPages"
        [orders]="orders"
        (pageChange)="updatePageNumber($event)"
      >
      </app-page-index>
    </div>
  </div>
<!--  
  <div *ngIf="challanNumber" class="popup" (click)="onPopupClick($event)">
    <div class="popup-content">
      <p>
        {{ challanNumber ? 'Challan Number: ' + challanNumber : 'No Challan Number Added' }}
      </p>

     
    </div>
  
  </div> -->
  <div *ngIf="showPopup" class="popup" (click)="onPopupClick($event)">
    <div class="popup-content">
        <p>
            {{ (challanNumber === null || challanNumber === undefined) ? 
                'No Challan Number Added' : 
                'Challan Number: ' + challanNumber }}
        </p>
      
    </div>
</div>

</section>