// date-range.component.ts

import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {
    @Input() type: string = 'NEW';
    @Output() dateRangeChange = new EventEmitter<{
        fromDate: any;
        toDate: any;
        selectedOptions: string[];
        selectedApplyOptions: string[];
        dateOption: string;  // <-- Add this
    }>();

    isOpen = false;
    selectedOptions: string[] = ['Any Date'];
    selectedApplyOptions: string[] = ['Order Date']; // Initialize with default
    dateOptions = ['Any Date', 'Today', 'Last 7 Days', 'Last 30 Days', 'Last Year', 'Custom'];
    applyOnOptions: string[] = [];  // Initialize as an empty array
    dateOption: string = 'Any Date'; // Initialize dateOption

    fromDate: any = '';
    toDate: any = '';
    calendarIcon = `${environment.assetUrl}assets/orderthrough/calendar.png`;
    dropdownIcon = `${environment.assetUrl}assets/orderthrough/cross.svg`;

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        const element = event.target as HTMLElement;
        if (!element.closest('.date-range-container')) {
            this.isOpen = false;
        }
    }

    ngOnInit() {
      console.log('Initial component state:', {
          fromDate: this.fromDate,
          toDate: this.toDate,
          selectedOptions: this.selectedOptions,
          selectedApplyOptions: this.selectedApplyOptions
      });
      this.setApplyOnOptions();
  }


    setApplyOnOptions() {
        if (this.type === 'NEW') {
            this.applyOnOptions = ['Order Date', 'Pickup Date'];
            if (!this.applyOnOptions.includes(this.selectedApplyOptions[0])) {
                this.selectedApplyOptions = ['Order Date']; // Default to order date
            }
        } else {
            this.applyOnOptions = ['Order Date', 'Pickup Date', 'Delivery Date', 'Invoice Date'];
        }
    }

    ngOnChanges() {
        this.setApplyOnOptions();
    }

    toggleDropdown() {
        this.isOpen = !this.isOpen;
    }

    onOptionChange(option: string) {
        this.selectedOptions = [option];
        this.dateOption = option;

        if (option !== 'Custom') {
            this.fromDate = '';
            this.toDate = '';
            this.emitDateRange();
        }

        this.isOpen = option === 'Custom';
    }

    onApplyOptionChange(option: string) {
        this.selectedApplyOptions = [option];
        this.emitDateRange();
    }

    getSelectedOptionsText(): string {
        if (this.selectedOptions[0] === 'Custom' && this.fromDate && this.toDate) {
            return `${this.formatDate(this.fromDate)} - ${this.formatDate(this.toDate)}`;
        }
        return this.selectedOptions[0];
    }

    saveCustomDate() {
      console.log('Save Custom Date called with:', {
          fromDate: this.fromDate,
          toDate: this.toDate
      });
  
      if (!this.fromDate || !this.toDate) {
          console.log('Missing date values');
          return;
      }
  
      // Keep original format from date picker (YYYY-MM-DD)
      const originalFromDate = this.fromDate;
      const originalToDate = this.toDate;
  
      // Store original YYYY-MM-DD format for internal use
      this.fromDate = originalFromDate;
      this.toDate = originalToDate;
      
      this.selectedOptions = ['Custom'];
      this.dateOption = 'Custom';
  
      // Only format in emitDateRange function
      this.emitDateRange();
      this.isOpen = false;
  
      console.log('Final saved dates:', {
          fromDate: this.fromDate,
          toDate: this.toDate,
          selectedOptions: this.selectedOptions,
          displayFormat: {
              from: this.formatDate(this.fromDate),
              to: this.formatDate(this.toDate)
          },
          apiFormat: {
              from: this.formatDateForAPI(this.fromDate),
              to: this.formatDateForAPI(this.toDate)
          }
      });
  }
    cancelCustomDate() {
        this.fromDate = '';
        this.toDate = '';
        this.selectedOptions = ['Any Date'];
        this.dateOption = 'Any Date';
        this.emitDateRange();
        this.isOpen = false;
    }

    private emitDateRange() {
      // Add validation before emitting
      if (this.selectedOptions[0] === 'Custom' && (!this.fromDate || !this.toDate)) {
          console.log('Date validation failed:', {
              fromDate: this.fromDate,
              toDate: this.toDate
          });
          return;
      }
  
      // Add console.log to see what's being emitted
      console.log('Emitting date range:', {
          fromDate: this.fromDate ? this.formatDateForAPI(this.fromDate) : '',
          toDate: this.toDate ? this.formatDateForAPI(this.toDate) : '',
          selectedOptions: this.selectedOptions,
          selectedApplyOptions: this.selectedApplyOptions,
          dateOption: this.selectedOptions[0]
      });
  
      this.dateRangeChange.emit({
          fromDate: this.fromDate ? this.formatDateForAPI(this.fromDate) : '',
          toDate: this.toDate ? this.formatDateForAPI(this.toDate) : '',
          selectedOptions: this.selectedOptions,
          selectedApplyOptions: this.selectedApplyOptions,
          dateOption: this.selectedOptions[0]
      });
  }

  private formatDateForAPI(date: string): string {
    if (!date) return '';
    // Input will be in YYYY-MM-DD format from input type="date"
    const [year, month, day] = date.split('-');
    // Return in DD/MM/YYYY format as expected by backend
    return `${day}/${month}/${year}`; // Changed forward slashes to match required format
}
  private formatDate(date: string): string {
      if (!date) return '';
      // Input will be in YYYY-MM-DD format from input type="date"
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`; // Converting to DD-MM-YYYY for display
  }

    // NEW: Method to reset the date range filters
    resetDateRange() {
        this.fromDate = '';
        this.toDate = '';
        this.selectedOptions = ['Any Date'];
        this.selectedApplyOptions = ['Order Date'];
        this.dateOption = 'Any Date';
        this.emitDateRange();
        this.isOpen = false;
    }
}