import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-date-archive',
  templateUrl: './date-archive.component.html',
  styleUrls: ['./date-archive.component.scss']
})
export class DateArchiveComponent implements OnInit {
  @Output() dateChange = new EventEmitter<any>();

  isOpen = false;
  selectedDateType = '';
  activeFilter = 'Any Date';
  dateRange = {
    from: '',
    to: ''
  };
  calendarIcon = `${environment.assetUrl}assets/orderthrough/calendar.png`;
  dropdownIcon = `${environment.assetUrl}assets/orderthrough/cross.svg`;

  dateOptions = [
    'Any Date',
    'Today',
    'Last 7 Days',
    'Last 30 Days',
    'Last Year',
    'Custom'
  ];

  constructor() {
    // Move click listener to ngOnInit
  }

  ngOnInit() {
    // Add click listener after component is initialized
    document.addEventListener('click', this.handleClickOutside.bind(this));
  }

  // Cleanup listener on component destroy
  ngOnDestroy() {
    document.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  private handleClickOutside(event: any) {
    console.log('Click event detected:', event.target);
    const dropdownElement = event.target.closest('.filter-dropdown');
    if (!dropdownElement) {
      console.log('Click outside dropdown - closing');
      this.isOpen = false;
    }
  }

  toggleDropdown(event: Event) {
    console.log('Toggle dropdown called');
    event.stopPropagation(); // Prevent event from bubbling up
    this.isOpen = !this.isOpen;
    console.log('Dropdown state:', this.isOpen);
  }

  onDateTypeChange() {
    console.log('Date type changed:', this.selectedDateType);
    if (this.activeFilter !== 'Any Date' && this.activeFilter !== 'Custom') {
      this.selectDateOption(this.activeFilter);
    }
  }

  selectDateOption(option: string) {
    console.log('Selected option:', option);
    if (!this.selectedDateType && option !== 'Any Date') {
      console.log('No date type selected, returning');
      return;
    }

    this.activeFilter = option;
    const today = new Date();
    let fromDate = new Date();

    switch (option) {
      case 'Any Date':
        this.dateRange = { from: '', to: '' };
        this.selectedDateType = '';
        break;
      case 'Today':
        this.dateRange = {
          from: this.formatDate(today),
          to: this.formatDate(today)
        };
        break;
      case 'Last 7 Days':
        fromDate.setDate(today.getDate() - 7);
        this.dateRange = {
          from: this.formatDate(fromDate),
          to: this.formatDate(today)
        };
        break;
      case 'Last 30 Days':
        fromDate.setDate(today.getDate() - 30);
        this.dateRange = {
          from: this.formatDate(fromDate),
          to: this.formatDate(today)
        };
        break;
      case 'Last Year':
        fromDate.setFullYear(today.getFullYear() - 1);
        this.dateRange = {
          from: this.formatDate(fromDate),
          to: this.formatDate(today)
        };
        break;
      case 'Custom':
        break;
    }

    console.log('Date range updated:', this.dateRange);
    if (option !== 'Custom') {
      this.emitDateChange();
    }
  }

  handleCustomDateChange() {
    console.log('Custom date changed:', this.dateRange);
    if (this.dateRange.from && this.dateRange.to) {
      this.emitDateChange();
    }
  }

// Inside DateArchiveComponent, in the emitDateChange function:

emitDateChange() {
  const dateEvent = {
    dateType: this.selectedDateType,
    dateRange: this.activeFilter, // Or a more specific mapping if needed
    from: this.dateRange.from,
    to: this.dateRange.to
  };
  console.log('Emitting date change:', dateEvent);
  this.dateChange.emit(dateEvent);
}

  private formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }
}