<app-back-btn></app-back-btn>
<div class="btn-container">
  <div class="btn-wrapper">
    <button class="btn-primary" (click)="print()">Print</button>
  </div>
</div>

<div class="barcode-container" id="barCodeContainer">
  <div class="barcode-item" *ngFor="let item of barcodeItems">
    <div class="store-name">{{ storeName }}</div>
    <div class="name">{{ item.customerName }}</div>
    <div class="parenttrade-name">{{ item.parenttradeName }}</div>

    <div class="cloth-name" *ngIf="!item.isService && item.cloth_name">
      {{ item.cloth_name }}
      <span *ngIf="item.itemNumber"> ({{ item.itemNumber }}/{{ item.num_of_items }})</span>
    </div>

    <div class="booking-quantity" *ngIf="item.parenttradeId !== 1 && item.parenttradeId !== 3">
      QTY: {{ item.parenttradeQuantity }}
    </div>
    <div class="booking-quantity" *ngIf="!item.isService && (item.parenttradeId === 1 || item.parenttradeId === 3)">
      QTY: {{ item.bookings_quantity }}
    </div>

    <div class="totalquantity">{{ item.runningTotal }}/{{ item.totalQuantity }}</div>

    <div class="delivery-info" *ngIf="item.delivery_date || item.delivery_time">
      {{ item.delivery_date }} {{ item.delivery_time }}
    </div>
  </div>
</div>