<!-- bank-details-dialog.component.html -->
<div class="bank-dialog-container">
    <div class="dialog-header">
      <h2>Edit Bank Details</h2>
      <button class="close-button" (click)="onCancel()">
        <span>×</span>
      </button>
    </div>
  
    <div class="dialog-content">
      <!-- Bank Name -->
      <div class="input-group">
        <label for="bankName">Bank Name</label>
        <input
          id="bankName"
          [(ngModel)]="bankForm.bank_name"
          (blur)="validateInput('bank_name')"
          placeholder="Enter bank name"
          [class.invalid]="errors['bank_name']"
        >
        <div class="error-message" *ngIf="errors['bank_name']">
          {{ errorMessages['bank_name'] }}
        </div>
      </div>
  
      <!-- Account Number -->
      <div class="input-group">
        <label for="accountNumber">Account Number</label>
        <input
          id="accountNumber"
          [(ngModel)]="bankForm.bank_account"
          (blur)="validateInput('bank_account')"
          placeholder="Enter account number"
          [class.invalid]="errors['bank_account']"
        >
        <div class="error-message" *ngIf="errors['bank_account']">
          {{ errorMessages['bank_account'] }}
        </div>
      </div>
  
      <!-- IFSC Code -->
      <div class="input-group">
        <label for="ifscCode">IFSC Code</label>
        <input
          id="ifscCode"
          [(ngModel)]="bankForm.bank_ifsc"
          (blur)="validateInput('bank_ifsc')"
          placeholder="Enter IFSC code (e.g., HDFC0123456)"
          [class.invalid]="errors['bank_ifsc']"
        >
        <div class="error-message" *ngIf="errors['bank_ifsc']">
          {{ errorMessages['bank_ifsc'] }}
        </div>
      </div>
  
      <!-- BHIM UPI -->
      <div class="input-group">
        <label for="bhimUpi">BHIM UPI (Optional)</label>
        <input
          id="bhimUpi"
          [(ngModel)]="bankForm.bhim_upi"
          (blur)="validateInput('bhim_upi')"
          placeholder="Enter UPI ID (e.g., username@upi)"
          [class.invalid]="errors['bhim_upi']"
        >
        <div class="error-message" *ngIf="errors['bhim_upi']">
          {{ errorMessages['bhim_upi'] }}
        </div>
      </div>
  
      <!-- Current Details -->
      <div class="current-details" *ngIf="data.bankName || data.accountNumber || data.ifscCode || data.bhimUpi">
        <h3>Current Details</h3>
        <div class="details-grid">
          <div class="detail-item" *ngIf="data.bankName">
            <span class="label">Bank Name:</span>
            <span class="value">{{data.bankName}}</span>
          </div>
          <div class="detail-item" *ngIf="data.accountNumber">
            <span class="label">Account Number:</span>
            <span class="value">{{data.accountNumber}}</span>
          </div>
          <div class="detail-item" *ngIf="data.ifscCode">
            <span class="label">IFSC Code:</span>
            <span class="value">{{data.ifscCode}}</span>
          </div>
          <div class="detail-item" *ngIf="data.bhimUpi">
            <span class="label">UPI ID:</span>
            <span class="value">{{data.bhimUpi}}</span>
          </div>
        </div>
      </div>
  
      <!-- General Error Message -->
      <div class="error-message" *ngIf="errorMessages['general']">
        {{ errorMessages['general'] }}
      </div>
    </div>
  
    <div class="dialog-footer">
      <button
        class="cancel-button"
        (click)="onCancel()"
        [disabled]="isSubmitting"
      >
        Cancel
      </button>
      <button
        class="submit-button"
        (click)="onSubmit()"
        [disabled]="isSubmitting"
      >
        <span class="button-content">
          <span class="button-text">{{ isSubmitting ? 'Updating...' : 'Update Details' }}</span>
          <div class="spinner" *ngIf="isSubmitting"></div>
        </span>
      </button>
    </div>
  </div>