import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/service/utility.service';
import { ApiService } from 'src/app/services/api.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gst-report',
  templateUrl: './gst-report.component.html',
  styleUrls: ['./gst-report.component.scss'],
})
export class GstReportComponent implements OnInit {
  @Input() invoices: any[] | null = null;
  from: string = '';
  to: string = '';
  today: string = new Date().toISOString().split('T')[0];
  message: string = '';
  messageType: number = 0;
  data: any[] = [];
  sortColumn: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';
  
  cards = [
    { title: 'TOTAL TAXABLE VALUE:', value: '0', iconSrc: `${environment.assetUrl}assets/images/TAX.svg`},
    { title: 'TOTAL CGST VALUE:', value: '0', iconSrc: `${environment.assetUrl}assets/images/CGST.svg`},
    { title: 'TOTAL SGST VALUE:', value: '0', iconSrc: `${environment.assetUrl}assets/images/SGST.svg` },
    { title: 'TOTAL INVOICE VALUE:', value: '0', iconSrc: `${environment.assetUrl}assets/images/SGST.svg` }
  ];

  public export = `${environment.assetUrl}assets/images/export.svg`;

  constructor(
    private http: HttpClient,
    private utility: UtilityService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.setToday();
  }

  setToday() {
    const today = new Date();
    this.today = today.toISOString().split('T')[0];
  }

  sortData(column: string) {
    // If clicking the same column, toggle direction
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // New column, default to ascending
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }

    if (this.data && Array.isArray(this.data)) {
      const sortedData = [...this.data];
      sortedData.sort((a, b) => {
        let valueA: any;
        let valueB: any;

        switch(column) {
          case 'invoice_number':
            // Remove any non-numeric characters for proper numeric sorting
            valueA = parseInt(String(a[column]).replace(/\D/g, ''));
            valueB = parseInt(String(b[column]).replace(/\D/g, ''));
            break;
          case 'invoice_date':
            valueA = new Date(a[column]).getTime();
            valueB = new Date(b[column]).getTime();
            break;
          case 'taxable_value':
          case 'cgst':
          case 'sgst':
          case 'final_amount':
            valueA = parseFloat(a[column]);
            valueB = parseFloat(b[column]);
            break;
          default:
            valueA = (a[column] || '').toString().toLowerCase();
            valueB = (b[column] || '').toString().toLowerCase();
        }

        if (valueA < valueB) {
          return this.sortDirection === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
          return this.sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      });

      this.data = sortedData;
    }
  }

  getSortIcon(column: string): string {
    if (this.sortColumn !== column) {
      return '↕';
    }
    return this.sortDirection === 'asc' ? '↑' : '↓';
  }

  onDateRangeSelected(dateRange: { from: string, to: string }) {
    this.from = dateRange.from;
    this.to = dateRange.to;
    this.generateReport();
  }

  generateReport() {
    this.data = null;
    this.apiService.generateGST(this.from, this.to).subscribe(
      (response: any) => {
        if (response.data.length === 0) {
          this.message = 'No data found for the selected date range';
          this.messageType = 2;
          return;
        }
        else{
          this.message = '';
        }
  
        this.data = response.data;
  
        // Calculate totals
        const totalTaxableValue = this.data.reduce((sum, data) => sum + data.taxable_value, 0);
        const totalCGSTValue = this.data.reduce((sum, data) => sum + data.cgst, 0);
        const totalSGSTValue = this.data.reduce((sum, data) => sum + data.sgst, 0);
        const totalInvoiceValue = this.data.reduce((sum, data) => sum + data.final_amount, 0);
  
        // Update cards
        this.cards[0].value = totalTaxableValue.toFixed(2);
        this.cards[1].value = totalCGSTValue.toFixed(2);
        this.cards[2].value = totalSGSTValue.toFixed(2);
        this.cards[3].value = totalInvoiceValue.toFixed(2);
      },
      (err) => {
        this.message = err.error.message;
        this.messageType = 1;
      }
    );
  }

  exportReport() {
    const fileName = `GST_Report_${this.from}_to_${this.to}.xlsx`;
  
    const exportData = this.data.map((item, index) => ({
      'S.No.': index + 1,
      'Booking ID': item.order_number,
      'Invoice No.': item.invoice_number,
      'Invoice Date': new Date(item.invoice_date).toLocaleDateString(),
      'Customer Name': item.name,
      'Customer Ph. No.': item.customer_phone,
      'Payment Status':item.payment_status,
      'Customer GSTIN': item.customer_gst,
      'Place of Supply': item.city_name,
      'Taxable Value': item.taxable_value,
      'CGST': item.cgst,
      'SGST': item.sgst,
      'Total Invoice Value': item.final_amount,
    }));
  
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'GST Report');
  
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob: Blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }

  typeOfMessage(type: string | string[]): boolean {
    return Array.isArray(type);
  }
}