<div class="gst-dialog-container">
    <div class="dialog-header">
      <h2>Edit REG FRANCHISE NAME</h2>
      <button class="close-button" (click)="onCancel()">
        <span>×</span>
      </button>
    </div>
    
    <div class="dialog-content">
      <div class="input-group">
        <label>NAME</label>
        <input
          [(ngModel)]="newFranchiseName"
          (input)="validateInput()"
          placeholder="Enter New Reg franchise name"
        >
        <div class="validation-messages" *ngIf="validationErrors.length > 0">
          <div class="error-message" *ngFor="let error of validationErrors">
            {{ error }}
          </div>
        </div>
      </div>
      
      <div class="current-name" *ngIf="data.reg_franchise_name">
        <span class="label">Current name:</span>
        <span class="value">{{data.reg_franchise_name}}</span>
      </div>
    </div>
    
    <div class="dialog-footer">
      <button
        class="cancel-button"
        (click)="onCancel()"
        [disabled]="isSubmitting"
      >
        Cancel
      </button>
      <button
        class="submit-button"
        (click)="onSubmit()"
        [disabled]="isSubmitting || !isValid"
      >
        <span class="button-content">
          <span class="button-text">{{ isSubmitting ? 'Updating...' : 'Update' }}</span>
          <div class="spinner" *ngIf="isSubmitting"></div>
        </span>
      </button>
    </div>
  </div>