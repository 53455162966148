import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, switchMap } from 'rxjs/operators';
import { SearchQueryService } from 'src/app/service/search-query.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() orderType: 'NEW' | 'INPRO' = 'NEW';
  @Output() searchPerformed = new EventEmitter<{ query: string, orders: any[], totalPages: number }>();
  public search = '../../../assets/images/icons/searching.svg';
  public searchQuery: string = '';
  public currentPage: number = 1;
  public totalPages: number = 0;
  public sortQuery: string = '';
  public selectedFilter: string = '';

  private searchSubject = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(private searchService: SearchQueryService) { }

  ngOnInit(): void {
    console.log("SearchComponent: ngOnInit");
    this.setupSearchObservable();
  }

  ngOnDestroy(): void {
    console.log("SearchComponent: ngOnDestroy");
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupSearchObservable(): void {
    console.log("SearchComponent: setupSearchObservable");
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(query => {
        console.log(`SearchComponent: Query received: ${query}`);
        return this.searchService.searchBookings(
          query,
          this.sortQuery,
          this.currentPage,
          this.selectedFilter,
          this.orderType
        );
      }),
      takeUntil(this.destroy$)
    ).subscribe(data => {
      console.log(`SearchComponent: Data received: ${JSON.stringify(data)}`);
      this.searchPerformed.emit({
        query: this.searchQuery,
        orders: data.data,
        totalPages: data.totalPages
      });
    });
  }

  handleSearch(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.performSearch((e.target as HTMLInputElement).value);
    }
  }

  handleInputChange(e: Event) {
    const value = (e.target as HTMLInputElement).value;
    this.performSearch(value);
  }

  private performSearch(value: string) {
    console.log(`SearchComponent: performSearch called with value: ${value}`);
    this.searchQuery = value;
    this.searchSubject.next(this.searchQuery);
  }

  updateOrderType(newOrderType: 'NEW' | 'INPRO') {
    console.log(`SearchComponent: Updating order type to ${newOrderType}`);
    this.orderType = newOrderType;
    // Trigger a new search with the current query and updated order type
    this.searchSubject.next(this.searchQuery);
  }

  // NEW: Method to reset the search input
  resetSearchInput() {
    console.log("SearchComponent: resetSearchInput called");
    this.searchQuery = '';
    this.searchPerformed.emit({ query: '', orders: [], totalPages: this.totalPages });
  }
}