import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';
import { UpdateStoreService } from '../service/updatestore.service';



@Component({
  selector: 'app-franchise-edit-dialog',
  templateUrl: './franchise-edit-dialog.component.html',
  styleUrls: ['./franchise-edit-dialog.component.scss']
})
export class FranchiseEditDialogComponent  {

  newFranchiseName: string = '';
  validationErrors: string[] = [];
  isSubmitting = false;
  isValid = false;

  constructor(
    public dialogRef: MatDialogRef<FranchiseEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private utils: UtilityService,
     public updateStoreService: UpdateStoreService,
  ) {}

  ngOnInit(): void {
    this.newFranchiseName = this.data.reg_franchise_name || '';
  }

  validateInput() {
    this.validationErrors = [];
    if (!this.newFranchiseName.trim()) {
      this.validationErrors.push('Franchise name is required');
    }
    if (this.newFranchiseName.length > 100) {
      this.validationErrors.push('Franchise name must be less than 100 characters');
    }
    this.isValid = this.validationErrors.length === 0;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

 
  // In your franchise name component
  onSubmit(): void {
    if (!this.isValid || this.isSubmitting) {
      return;
    }
    
    this.isSubmitting = true;
    
    this.updateStoreService.updateFranchiseName(this.data.storeId, this.newFranchiseName)
      .subscribe({
        next: (response) => {
          console.log('Franchise name updated successfully:', response);
          this.dialogRef.close(this.newFranchiseName);
        },
        error: (error) => {
          console.error('Error updating franchise name:', error);
          this.isSubmitting = false;
          this.validationErrors = ['Failed to update franchise name. Please try again.'];
        }
      });
  }
  

}
