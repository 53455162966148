import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-archive-order-source',
  templateUrl: './archive-order-source.component.html',
  styleUrls: ['./archive-order-source.component.scss']
})
export class ArchiveOrderSourceComponent implements OnInit {

  sources: string[] = ['Walk-In', 'Store', 'Whatsapp', 'Customer Care', 'Mobile App', 'Website'];
  selectedSources: string[] = [];
  isOpen: boolean = false;
  dropIcon = `${environment.assetUrl}assets/orderthrough/cross.svg`;
  dropdownIcon = `${environment.assetUrl}assets/orderthrough/cross.svg`;

  @Output() sourcesChanged = new EventEmitter<string[]>();

  constructor() {}

  ngOnInit(): void {}

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  toggleSource(source: string): void {
    const index = this.selectedSources.indexOf(source);
    if (index === -1) {
      this.selectedSources.push(source);
    } else {
      this.selectedSources.splice(index, 1);
    }
    this.isOpen = false;
    this.sourcesChanged.emit(this.selectedSources);
  }

  closeDropdown(): void {
    this.isOpen = false;
  }

  get displayText(): string {
    return this.selectedSources.length > 0
      ? this.selectedSources.join(', ')
      : 'Order Source';
  }
}

