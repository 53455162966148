import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-item-quantity',
  templateUrl: './item-quantity.component.html',
  styleUrls: ['./item-quantity.component.scss']
})
export class ItemQuantityComponent implements OnInit {
 ngOnInit(): void {
   
 }
  @Input() quantity: number = 0;
  @Output() quantityChange = new EventEmitter<number>();

  incrementQuantity() {
    this.quantity++;
    this.quantityChange.emit(this.quantity);
  }

  decrementQuantity() {
    if (this.quantity > 0) {
      this.quantity--;
      this.quantityChange.emit(this.quantity);
    }
  }


  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);
    if (!isNaN(value)) {
      this.quantity = value;
      this.quantityChange.emit(this.quantity);
    }
  }

}
